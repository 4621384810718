import React from 'react';

import { Navbar, Nav, NavItem, Col } from 'reactstrap';
import FooterPage from './FooterPage';
const Footer = () => {
  return (
    <Navbar>
      <Nav navbar style={{ width: '100%' }}>
        <NavItem
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 11,
          }}
        >
          <FooterPage />
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;
