import { STATE_LOGIN } from 'components/AuthForm';
import React from 'react';
import {
  Card,
  Col,
  Button,
  Row,
  CardFooter,
  CardBody,
  Label,
  Input,
  Form,
  FormGroup,
  Modal,
  ModalBody,
} from 'reactstrap';
import { getInstagram, getYoutube, getBlog } from '../utils/Formcheck';
import { setSession, getInfo, removeForceSession } from '../utils/auth';
import logoImg from '../assets/img/puniclogo.png';
import headerImg from '../assets/img/bettery_hand.jpg';
import { RequestAxios } from '../utils/request';
import Loader from 'react-spinners/RotateLoader';
import { css } from '@emotion/react';
import {
  ToastsStore,
  ToastsContainer,
  ToastsContainerPosition,
} from 'react-toasts';
import insta from '../assets/img/headericon/insta.png';
import instaDeactive from '../assets/img/headericon/insta_deactive.png';
import youtube from '../assets/img/headericon/youtube.png';
import youtubeDeactive from '../assets/img/headericon/youtube_deactive.png';
import blog from '../assets/img/headericon/blog.png';
import blogDeactive from '../assets/img/headericon/blog_deactive.png';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import { initSocketConnection } from '../components/Socket';
import FooterPage from '../components/Layout/FooterPage';
import HeaderSocial from '../components/Layout/HeaderSocial';

const cookies = new Cookies();

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const StyledInput = styled.input`
  appearance: none;
  border: 1.5px solid gainsboro;
  border-radius: 0.35rem;
  margin-top: 4px !important;
  width: 20px;
  height: 20px;

  &:checked {
    border-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    background-size: 100% 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: limegreen;
  }
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
`;

const StyledP = styled.p`
  margin: 0 !important;
  font-size: 14px;
`;

const linkOpen = juso => {
  window.open(`${juso}`);
};

class AuthPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      pwd: '',
      idForget: false,
      iconInsta: instaDeactive,
      iconYoutube: youtubeDeactive,
      iconBlog: blogDeactive,
    };

    this.Login = this.Login.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  // 엔터 키 이벤트 핸들러
  handleKeyDown(event) {
    if (event.key === 'Enter') {
      // 엔터 키가 눌렸을 때 실행할 동작
      console.log('Enter key pressed');
      this.Login(); // 예: 로그인 함수 호출
    }
  }

  Login = async () => {
    try {
      this.setState({
        loading: true,
      });
      let method = 'post';
      let url = 'user/auth';
      let data = new Map();

      if (this.state.id) {
        data.set('id', this.state.id);
      } else {
        throw '아이디(이메일)를 입력해주세요.';
      }

      if (this.state.pwd) {
        data.set('pwd', this.state.pwd);
      } else {
        throw '비밀번호를 입력해주세요.';
      }

      const response = await RequestAxios(data, method, url);

      if (response !== 9999 || response !== 10000) {
        if (response.data.result === 0) {
          setSession(response.data.sid, response.data.role, response.data.rank);

          setTimeout(() => {
            this.setState({
              loading: false,
            });
            cookies.set('email', this.state.id);

            ToastsStore.success('로그인 성공');
            console.log(response.data);
            setTimeout(() => {
              if (response.data.role == 1) {
                window.location.href = './main';
              } else {
                if (response.data.adminAuth == 2) {
                  window.location.href = './adminmenuselect';
                } else {
                  cookies.set('adminSelect', 1);
                  window.location.href = './main';
                }

                // this.props.history.push('/main');
              }
              initSocketConnection();
            }, 200);
          }, 500);
        } else if (response.data.result === 2) {
          throw '아이디 또는 비밀번호를 다시 확인해주세요.';
        } else if (response.data.result === 3) {
          throw '세션 저장 실패';
        } else if (response.data.result === 5) {
          throw '등록되어있지 않은 아이디 입니다.';
        } else {
          throw '로그인 실패';
        }
      } else {
        if (response === 10000) {
          throw '사용할수 없는 문자열이 포함되어 있습니다.';
        }
      }
    } catch (error) {
      ToastsStore.error(String(error));

      this.setState({
        loading: false,
      });
    }
  };

  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push('/');
    } else {
      this.props.history.push('/signup');
    }
  };

  handleLogoClick = () => {
    this.props.history.push('/');
  };

  findSession = async () => {
    const info = await getInfo();

    if (info) {
      this.props.history.push('/main');
    } else {
      removeForceSession();
    }
  };

  componentDidMount = () => {
    if (cookies.get('email')) {
      this.setState({
        id: cookies.get('email'),
        idForget: true,
      });
    }
    if (cookies.get('token')) {
      this.findSession();
    }
  };

  render() {
    return (
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ToastsContainer
          position={ToastsContainerPosition.BOTTOM_RIGHT}
          store={ToastsStore}
          lightBackground
        />

        <Modal
          key={'loadingModal'}
          isOpen={this.state.loading}
          centered={true}
          className="loading-container"
          fade={false}
        >
          <ModalBody style={{ display: 'none' }}></ModalBody>
          <div className="overlay-box" width="100%">
            <Loader
              color={'#fff'}
              loading={this.state.loading}
              css={override}
              size={15}
              // height={400}
              // width={100}
              // radius={10}
              // margin={20}
              speedMultiplier={0.4}
            />
          </div>
        </Modal>

        <Col
          lg={12}
          md={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Card>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                maxWidth: 600,
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <HeaderSocial logo={'/'} />
              </div>
              <div style={{ overflowY: 'hidden', height: 300 }}>
                <img src={`${headerImg}`} style={{ width: '100%' }} />
              </div>

              <div style={{ width: '100%', marginBottom: 20 }}>
                <CardBody style={{ border: 0 }}>
                  <Form>
                    <FormGroup>
                      <Label
                        for="exampleEmail"
                        sm={12}
                        style={{ fontWeight: 'bold' }}
                      >
                        아이디
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="email"
                          name="id"
                          value={this.state.id}
                          onChange={text => {
                            this.setState({
                              id: text.target.value,
                            });
                          }}
                          onKeyDown={this.handleKeyDown}
                          placeholder="아이디를 입력해주세요."
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label
                        for="examplePassword"
                        sm={12}
                        style={{ fontWeight: 'bold' }}
                      >
                        비밀번호
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="password"
                          name="pwd"
                          autoComplete="new-password"
                          value={this.state.pwd}
                          onChange={text => {
                            this.setState({
                              pwd: text.target.value,
                            });
                          }}
                          onKeyDown={this.handleKeyDown}
                          placeholder="비밀번호를 입력해주세요."
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Col sm={12} style={{ paddingTop: 10 }}>
                        <Button
                          style={{ width: '100%' }}
                          onClick={() => {
                            this.Login();
                          }}
                        >
                          로그인
                        </Button>
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Col
                        sm={12}
                        style={{
                          paddingTop: 10,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Col
                          sm={6}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            cursor: 'pointer',
                            alignItems: 'center',
                          }}
                        >
                          <StyledLabel htmlFor={'privacy'}>
                            <StyledInput
                              type="checkbox"
                              style={{ marginRight: 10 }}
                              onChange={() => {
                                if (!this.state.idForget) {
                                  this.setState({
                                    idForget: true,
                                  });
                                } else {
                                  this.setState({
                                    idForget: false,
                                  });
                                }
                              }}
                              checked={this.state.idForget}
                            />
                            <StyledP
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: 5,
                              }}
                            >
                              아이디 저장
                            </StyledP>
                          </StyledLabel>
                        </Col>
                        <Col
                          sm={6}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            height: 29,
                            alignItems: 'center',
                          }}
                        >
                          <span
                            className="auth_register_text"
                            onClick={() => {
                              this.props.history.push('/registeragree');
                            }}
                            style={{ cursor: 'pointer', fontSize: 14 }}
                          >
                            회원가입
                          </span>
                        </Col>
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Col
                        sm={12}
                        style={{
                          paddingTop: 10,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Col
                          sm={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            paddingLeft: '3%',
                            cursor: 'pointer',
                            fontSize: 14,
                          }}
                        >
                          <span
                            className="auth_findid"
                            onClick={() => {
                              this.props.history.push('/findid');
                            }}
                          >
                            아이디찾기
                          </span>{' '}
                          /{' '}
                          <span
                            className="auth_findpwd"
                            onClick={() => {
                              this.props.history.push('/findpwd');
                            }}
                          >
                            비밀번호 찾기
                          </span>
                        </Col>
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Col
                        sm={12}
                        style={{
                          paddingTop: 10,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Button
                          style={{ width: '100%' }}
                          color={'primary'}
                          outline
                          onClick={() => {
                            this.props.history.push('/devicefind');
                          }}
                        >
                          설치위치 조회
                        </Button>
                      </Col>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: '7%',
                    fontSize: 11,
                  }}
                >
                  <FooterPage />
                </CardFooter>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default AuthPage;
