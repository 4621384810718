import React from 'react';

import { Col } from 'reactstrap';

const FooterPage = () => {
  return (
    <>
      <Col sm={6} style={{ paddingTop: 10 }}>
        <div>(주)쿳션</div>
        <div>Tel. 070-8959-2960</div>
        <div>평일 09:00 ~ 18:00 상담 가능</div>
      </Col>

      <Col sm={6} style={{ paddingTop: 10, textAlign: 'right' }}>
        <div>사업자등록번호 : 226-87-02635</div>
        <div>대표자:이민석</div>
      </Col>
    </>
  );
};

export default FooterPage;
