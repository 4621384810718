import React from 'react';
import {
  Card,
  Col,
  Button,
  Row,
  CardFooter,
  CardBody,
  Form,
  FormGroup,
  Modal,
  ModalBody,
} from 'reactstrap';
import { getInstagram, getYoutube, getBlog } from '../utils/Formcheck';
import logoImg from '../assets/img/puniclogo.png';
import headerImg from '../assets/img/bettery_hand.jpg';
import Loader from 'react-spinners/RotateLoader';
import { css } from '@emotion/react';
import {
  ToastsStore,
  ToastsContainer,
  ToastsContainerPosition,
} from 'react-toasts';
import insta from '../assets/img/headericon/insta.png';
import instaDeactive from '../assets/img/headericon/insta_deactive.png';
import youtube from '../assets/img/headericon/youtube.png';
import youtubeDeactive from '../assets/img/headericon/youtube_deactive.png';
import blog from '../assets/img/headericon/blog.png';
import blogDeactive from '../assets/img/headericon/blog_deactive.png';

import Cookies from 'universal-cookie';
import FooterPage from '../components/Layout/FooterPage';
import HeaderSocial from '../components/Layout/HeaderSocial';

const cookies = new Cookies();

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const linkOpen = juso => {
  window.open(`${juso}`);
};

class AdminMenuSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      pwd: '',
      idForget: false,
      iconInsta: instaDeactive,
      iconYoutube: youtubeDeactive,
      iconBlog: blogDeactive,
    };
  }

  render() {
    return (
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ToastsContainer
          position={ToastsContainerPosition.BOTTOM_RIGHT}
          store={ToastsStore}
          lightBackground
        />

        <Modal
          key={'loadingModal'}
          isOpen={this.state.loading}
          centered={true}
          className="loading-container"
          fade={false}
        >
          <ModalBody style={{ display: 'none' }}></ModalBody>
          <div className="overlay-box" width="100%">
            <Loader
              color={'#fff'}
              loading={this.state.loading}
              css={override}
              size={15}
              // height={400}
              // width={100}
              // radius={10}
              // margin={20}
              speedMultiplier={0.4}
            />
          </div>
        </Modal>

        <Col
          lg={12}
          md={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Card>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                maxWidth: 600,
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <HeaderSocial logo={'#'} />
              </div>
              <div style={{ overflowY: 'hidden', height: 300 }}>
                <img src={`${headerImg}`} style={{ width: '100%' }} />
              </div>

              <div style={{ width: '100%', marginBottom: 20 }}>
                <CardBody style={{ border: 0 }}>
                  <Form>
                    <FormGroup>
                      <Col
                        sm={12}
                        style={{
                          paddingTop: 30,
                          paddingBottom: 30,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 30,
                        }}
                      >
                        <Button
                          style={{ width: '100%', padding: 30 }}
                          color={'primary'}
                          outline
                          onClick={() => {
                            cookies.set('adminSelect', 1);
                            this.props.history.replace('/main');
                          }}
                        >
                          사용자 페이지
                        </Button>

                        <Button
                          style={{ width: '100%', padding: 30 }}
                          color={'danger'}
                          outline
                          onClick={() => {
                            cookies.set('adminSelect', 2);
                            this.props.history.replace('/main');
                          }}
                        >
                          관리자 페이지
                        </Button>
                      </Col>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: '7%',
                    fontSize: 11,
                  }}
                >
                  <FooterPage />
                </CardFooter>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default AdminMenuSelect;
