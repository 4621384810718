import React from 'react';
import { Nav } from 'reactstrap';
import Avatar from '../Avatar';
import logo from '../../assets/img/puniclogo.png';
import insta from '../../assets/img/headericon/insta.png';
import instaDeactive from '../../assets/img/headericon/insta_deactive.png';
import youtube from '../../assets/img/headericon/youtube.png';
import youtubeDeactive from '../../assets/img/headericon/youtube_deactive.png';
import blog from '../../assets/img/headericon/blog.png';
import blogDeactive from '../../assets/img/headericon/blog_deactive.png';
import {
  Mobile,
  getInstagram,
  getYoutube,
  getBlog,
} from '../../utils/Formcheck';
import withBadge from 'hocs/withBadge';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import bn from 'utils/bemnames';

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);

const linkOpen = juso => {
  window.open(`${juso}`);
};

class HeaderSocial extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: true,
    isOpenUserCardPopover: false,
    iconInsta: instaDeactive,
    iconYoutube: youtubeDeactive,
    iconBlog: blogDeactive,
    logoLink: this.props.logo ? this.props.logo : '/main',
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {
    const { isNotificationConfirmed, iconInsta, iconYoutube, iconBlog } =
      this.state;

    return (
      <div
        style={{
          minHeight: 35,
        }}
      >
        <Nav
          navbar
          style={{
            height: 35,
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <img
            src={`${logo}`}
            onClick={() => {
              window.location.href = this.state.logoLink;
            }}
            style={{
              height: '100%',
              cursor: 'pointer',
            }}
          />
          {this.props.history}
        </Nav>

        <Nav navbar className={bem.e('nav-right')}>
          {/* <Avatar
            src={`${iconInsta}`}
            rounded={false}
            circle={false}
            size={Mobile() ? 20 : 27}
            style={{ marginTop: 6, cursor: 'pointer' }}
            onMouseOver={() => {
              this.setState({
                iconInsta: insta,
              });
            }}
            onMouseOut={() => {
              this.setState({
                iconInsta: instaDeactive,
              });
            }}
            onClick={() => {
              linkOpen(getInstagram());
            }}
          />

          <Avatar
            src={`${iconYoutube}`}
            rounded={false}
            circle={false}
            size={Mobile() ? 20 : 27}
            style={{ marginTop: 6, marginLeft: 5, cursor: 'pointer' }}
            onMouseOver={() => {
              this.setState({
                iconYoutube: youtube,
              });
            }}
            onMouseOut={() => {
              this.setState({
                iconYoutube: youtubeDeactive,
              });
            }}
            onClick={() => {
              linkOpen(getYoutube());
            }}
          /> */}

          {/* <Avatar
            src={`${iconBlog}`}
            rounded={false}
            circle={false}
            size={Mobile() ? 20 : 27}
            style={{ marginTop: 6, marginLeft: 5, cursor: 'pointer' }}
            onMouseOver={() => {
              this.setState({
                iconBlog: blog,
              });
            }}
            onMouseOut={() => {
              this.setState({
                iconBlog: blogDeactive,
              });
            }}
            onClick={() => {
              linkOpen(getBlog());
            }}
          /> */}
        </Nav>
      </div>
    );
  }
}

export default HeaderSocial;
