export function useTerm() {
  return `서비스 이용 약관

  제 1장. 총칙
  
  제 1조. 목적
  본 약관은 ㈜쿳션(이하 “회사”라 함)이 제공하는 회원, 보상, 서비스 및 고객지원의 이용과 관련하여, 회사 및 회원의 제반 권리, 의무 및 정보보호, 관련 절차 등 기본적인 사항을 규정하는데 그 목적이 있습니다.
  
  제 2조. 용어의 정의
  본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
  1) 회원 (Member) : “회원”이란, 본 약관에 따라 가입신청에 대한 회사의 승낙을 받아 회사가 제공하는 서비스를 이용하는 자를 말합니다. “회원”은 아래 “정회원”과 “준회원”을 모두 포함해 통칭하는 의미이며, 그 외 회사의 서비스를 경험하지 않았거나 본 약관 <제 7조>의 이유로 회원자격이 상실된 非이용자는 “비회원”으로 정의합니다.
  ① 정회원 : 회사가 운영하는 홈페이지를 통해 본 약관 및 개인정보처리방침(개인정보 수집 및 이용 동의)에 동의하고, 정해진 가입 절차에 따라 휴대폰 인증을 완료해 회원 자격을 부여 받은 가입자를 의미합니다.
  ② 준회원 : 회사가 설치/운영하는 폐건전지 수거함을 이용한 이력이 있는 사용자로, 폐건전지 수거함의 이용 절차에 따라 개인 휴대전화 번호를 입력한 자를 의미합니다.
  2) “자원 (Resource)”이란, 회사가 폐건전지 수거함 및 관련 서비스를 통해 회수하는 소재 - 폐건전지 - 를 의미합니다. 회사가 회수하는 자원은 시장 및 서비스 환경에 따라 확대/변경되거나 축소/취소될 수 있습니다.
  3) “서비스 (Service)”란, 회사가 운영하는 홈페이지, 폐건전지 수거함, 운영 서비스 및 각종 프로그램, 관련 문화 활동 등을 통해 회원과 비회원에게 제공하는 모든 활동을 의미합니다.
  4) “아이디 (ID)”란, 회사의 서비스 이용과 고객 지원 등에 필수적으로 필요한 고객 식별자로, 회원 ID는 개인 휴대전화 번호와 동일합니다. ID에 대한 구체적인 사항은 본 약관 <제 15조>에 정한 바와 같습니다.
  5) “포인트 (Point)”란, 회원이 폐건전지 수거함을 이용해 투입한 자원에 대한 보상으로, 회사로부터 발급받은 회원 계정에 부여된 재화를 의미하며, 특정 포인트 이상 적립하면 현금처럼 이용이 가능합니다. 또한, 회사는 회사가 제공하는 다양한 서비스 및 이벤트에 대한 보상으로도 포인트를 제공할 수 있습니다. 포인트 적립 및 사용에 대한 구체적인 사항은 본 약관 <제 3장>에 정한 바와 같습니다.
  ① 보유 포인트 : 회사가 제공하는 서비스를 통해 확보한 포인트로, 회원이 현금으로 전환한 포인트는 포함되지 않는 ‘현재 포인트’를 의미합니다.
  ② 누적 포인트 : 회원이 회사가 제공하는 서비스를 이용한 시점부터 확보한, ‘총 합계 포인트’를 의미합니다. 이미 현금으로 전환한 포인트 역시 누적 포인트에 합산되어 제공됩니다.
  ③ 전환 가능 포인트 : 회원이 적립한 포인트 중 회사가 정한 전환 기준 금액을 초과하여 현금으로 전환 신청이 가능한 포인트를 의미합니다.
  ④ 전환 완료 포인트 : 회원이 보유한 현금전환 가능 포인트 중 이미 현금으로 전환 완료한 포인트를 의미합니다.
  ⑤ 사용 완료 포인트 : 회원이 보유한 포인트 중 현금전환 外의 방법으로 사용(차감)한 포인트를 의미합니다.
  6) “홈페이지 (Homepage)”란, 온라인을 통해 회사의 정보 및 각종 서비스를 제공받을 수 있는 웹사이트를 의미합니다. 회사는 www.punic.kr 도메인을 통해 홈페이지를 운영하고 있습니다.
  7) “폐건전지 수거함 (퓨닉)”이란, 재활용 가능한 폐건전지를 분리/수거하기 위해 개발한 회사의 스마트 수거함입니다.
  8) “운영서비스 (Field Service)”란, 회사가 폐건전지 수거함을 통해 분리된 자원을 올바른 재활용 프로세스(순환경제) 사이클로 인입시키기 위한 추가 분류, 수거 및 이동 등의 전담 및 회사 및 제휴사 직원의 폐건전지 수거함 설치 및 관리, 기기 및 부속의 클리닝과 고장 수리, 소프트웨어 업데이트, 대면고객 지원 등 오프라인을 기반으로 제공하는 일련의 서비스와 온∙오프라인을 통한 고객 지원을 모두 포함합니다.
  9) “제휴사/제휴고객 (Client)”이란, 회사의 폐건전지 수거함의 구매 및 운영 서비스 계약, 제휴 및 협력 사업, 폐건전지 자원을 재활용 해 소재화 한 고급 재활용 플레이크(r-flake) 구매 계약 등을 체결해 회사와 협력 관계를 갖게 된 모든 사업자를 통칭해 의미합니다.
  
  제 3조. 약관의 게시와 개정
  1) 회사는 회사가 제공하는 서비스를 이용하기 전에 본 약관을 게시하고, 회원이 이 약관의 중요한 내용을 확인할 수 있도록 합니다.
  2) 회사는 약관의 규제에 관한 법률(이하 “약관규제법”)에 근거하여 중대한 사업 내용이나 회원에게 불리한 항목의 개정이 있을 경우에 대해서도 그 내용을 충분히 알 수 있도록 게시하여 회원의 권익을 보호함과 동시에 공정한 효력이 발생하도록 합니다.
  3) 본 약관을 개정하고자 할 경우, 회사는 아래와 같은 절차와 방법으로 고지하여 회원의 서비스 이용에 불리함이 없도록 게시합니다.
  ① 일반적인 항목의 개정 : 시행 14일 이전 변경 내용을 공고합니다.
  ② 회사의 중요한 사업적 변화나 회원에게 불리한 항목의 개정 : 시행 30일 이전 변경 내용을 공고합니다.
  ③ 회사는 위의 공고 기간동안 가능한 모든 수단을 이용해 회원에게 본 약관의 변경 내용을 고지합니다.
  - 쿳션 홈페이지 및 공식 SNS 공지
  - 고객센터 (고객센터 ARS 홍보 및 상담원 안내) 공지
  - 휴대폰 SMS 발송
  - 회사가 보유한 회원 전자우편(e-mail, 회원가입 선택사항으로 보유 회원에 한함)
  4) 본 약관 <제 3조 2)항>의 조건을 만족한 개정 약관은 사전 공고한 시행일부터 그 효력이 발생합니다. 상기 고지방법 및 고지의 효력은 본 약관에서 달리 규정하지 않는 한 본 약관의 각 조항에서 규정하는 통보 또는 통지의 경우에 이를 준용합니다. 약관의 효력 발생일은 본 약관 <부칙>에서 확인할 수 있습니다.
  5) 본 약관의 개정과 관련하여 이의가 있는 회원은 회원탈퇴를 할 수 있으며, 개정된 약관의 효력 발생일까지 회원탈퇴 하지 않은 회원은 개정된 약관에 동의한 것으로 봅니다.
  
  제 4조. 약관의 해석
  1) 회사와 이용자 사이에 개별적으로 합의한 사항이 이 약관에 정한 사항과 다를 때에는, 그 합의사항을 이 약관에 우선하여 적용합니다.
  2) 이 약관에서 정하지 아니한 사항 및 당사자 간에 미리 합의되지 않은 사항에 처리에 관해서는 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”), 전자상거래법, 전자금융거래법, 약관규제법 등 국내 관계법령을 따르며, 그 외는 기타 상관례에 따릅니다.
  
  
  제 2장. 회원 정책
  
  제 5조. 회원가입
  1) 회원가입은 회사 홈페이지에서 정해진 절차를 통해 진행됩니다. 가입신청자는 본 이용약관 및 개인정보처리방침에 동의 절차를 거쳐 회사가 요구하는 가입신청 사항을 기입함으로써 회원으로 가입되며, 동시에 회사와 이용계약이 성립됩니다.
  ① 가입신청자는 본인 명의로 통신사에 가입된 휴대전화 또는 태블릿 등 본인인증을 구동할 수 있는 전자적 장치를 보유해야 합니다.
  ② 가입신청자는 반드시 신청자 본인의 명의로 가입 신청해야 하며, 본인확인기관 등을 통해 실시하는 본인확인을 통과하여야 합니다.
  ③ 회사는 본인의 명의가 아닌 경우, 이미 가입된 회원인 경우, 법인 명의로 가입하고자 할 경우 및 본 약관 및 기타 회사가 정한 제반 사항에 배치되는 경우 등에 한해서는 회원가입을 거절할 수 있습니다.
  2) 회원은 본인의 회원 자격을 타인에게 양도하거나 대여, 담보의 목적으로 이용할 수 없습니다.
  3) 단, 회원의 사망 및 행방불명 등 정상적인 활동이 어려운 경우는 아래 조건에 한하여 예외 적용합니다.
  ① 회원의 상속인이 정부 기관에서 발급받은 유효기간 3개월 이내의 증빙 서류를 제출함으로써 상속인 본인에 한하여 회원 자격을 양수할 수 있습니다.
  ② 회사는 상속인이 제출한 증빙 서류의 진위여부를 파악할 수 있으며, 이를 위한 전문가의 조언 및 기존 판례를 근거로 양도 요구를 거절할 수 있습니다.
  
  제 6조. 회원탈퇴
  1) 회원탈퇴는 회사 홈페이지에서 언제든지 가능합니다. 회사는 회원이 요청한 회원탈퇴 요구를 정해진 절차를 통해 제공받고, 조속히 필요한 제반 절차를 수행합니다.
  2) 회원탈퇴 요청 시, 회사는 관련 데이터 및 보유 포인트의 삭제, 소멸에 대해 동의를 받습니다. 회사는 회원이 아닌 사용자에 대해 관련 데이터를 보관할 수 없으므로, 이에 동의하지 않으면 회원탈퇴가 불가합니다.
  3) 정상적인 절차에 따른 회원탈퇴가 완료되면 회원과 관련한 데이터는 모두 삭제, 소멸되며 모든 회원 자격은 상실됩니다. 동시에 회사와 이용계약은 자동으로 해지됩니다.
  
  제 7조. 회원자격의 상실 및 정지
  1) 회사는 회원이 다음의 각 호에 해당하는 경우, 회원에 대한 통보로서 회원의 자격을 상실 또는 정지시킬 수 있습니다.
  ① 회원가입 신청 시 허위 내용을 등록하거나 타인의 명의를 도용한 경우
  ② 타인의 계정으로 포인트를 적립하거나 타인 계정의 포인트를 현금으로 전환 신청한 경우 (부정사용)
  ③ 허용되지 않는 부정한 방법으로 회사의 서비스를 이용하거나 포인트를 적립한 경우 (부정적립)
  - 회사의 기기 및 시스템을 해킹한 경우
  - 시스템 오류 및 알려지지 않은 버그 등을 이용해 반복적으로 포인트를 적립한 경우
  - 1개의 자원으로 2건 이상의 포인트를 적립한 경우 등
  ④ 고의로 회사의 자산을 훼손하거나 기기 고장을 유발하는 행위를 반복한 경우
  - 회사가 운영하는 기기의 전체 및 일부, 관련 부속품 등을 파손시키거나 고장을 유발한 경우
  - 쓰레기 및 오물 등을 회사의 기기에 투입하여 고장을 유발한 경우
  - 기기의 전원부 및 인터넷 연결부를 훼손/분리하여 정상적인 기기동작 및 운영을 방해한 경우 등
  ⑤ 회사 및 임직원, 기타 제3자의 명예를 훼손하는 경우
  - 상담직원에게 폭언, 욕설, 인격침해, 업무방해, 협박 등을 일삼는 경우
  - 수거함 운영 직원 및 관계사 직원에게 폭언, 욕설, 인격침해, 폭행 등 고의로 업무방해를 한 경우 등
  ⑥ 타인의 권리를 침해하거나 일상적인 사회통념에 허용되지 않는 행위를 지속/반복한 경우
  ⑦ 기타 관계 법령에 위배된다고 판단되는 경우
  2) 본 약관 <제 7조 1)항>에 해당하는 행위로 인해 회원의 자격이 상실/정지된 경우, 회사는 다음과 같은 후속조치를 취할 수 있습니다.
  ① 회원의 자격 상실 및 정지 사유를 당사자에게 통보하고 서비스 이용 정지 및 회원탈퇴 등의 조치를 취할 수 있습니다.
  ② 이를 이유로 고객센터의 업무를 방해하는 경우, 상담 및 운영 직원의 보호를 위해 일시적으로 상담 및 응대를 종료할 수 있습니다.
  ③ 타 회원의 권리 보호를 위해 정상적인 회사 서비스 제한 조치(포인트 미적립, 현금 환전 불가 및 부정적립 포인트 소멸 등)를 할 수 있습니다.
  ④ 위와 같은 조치에도 불구하고 지속/반복적인 업무 방해를 일삼는 경우, 회사는 회원 자격을 박탈하고 동일한 신상정보로 서비스에 재가입하는 것을 제한할 수 있습니다.
  ⑤ 이처럼 회원의 자격이 상실된 경우, 향후 서비스 부정 이용 방지 및 타 회원의 추가 피해방지, 타 회원의 권리보호를 위해 아래의 법령을 근거로 조치 후 3년 간 해당 이용자의 신상정보를 보유합니다.
  - 소비자의 불만 또는 분쟁처리에 관한 기록: 3년 보관 (전자상거래법)
  - 보유정보 : 휴대전화번호, 이름 등 회원가입시 기재한 정보 및 부정이용 기록
  - 삭제 및 소멸정보 : 회원 보유 포인트 및 정상이용 기록 등
  3) 회원은 본 조의 금지행위로 인한 회원자격 상실 및 정지 통보를 받은 날로부터 최대 30일 이내에 본인의 의견 및 상황을 소명할 수 있습니다. 회사는 회원의 소명 및 자료를 성실히 검토하여 그에 따른 결과 역시 최대 30일 이내에 당사자에게 통보합니다. 소명 결과에 따라 회원의 자격을 복원하거나 상실 및 정지를 유지할 수 있습니다.
  4) 회원이 본 조의 금지행위를 하는 경우 서비스 이용을 제한함과 별도로 손해배상의 청구, 사법당국에 대한 고발 등 법적 조치를 취할 수 있습니다. 특히 부정사용 및 부정적립을 통한 부당이득이 발생한 경우, 회사는 회원 및 협조/동조자에게 동일한 법적 조치를 취할 수 있습니다.
  5) 회원자격의 상실 및 정지와 관련해 별도 조항으로 언급되지 않은 항목은 일반적인 회원탈퇴 과정에 준하여 처리됩니다.
  
  제 8조. 만 14세 미만 사용자의 회원가입에 대한 특칙
  회사는 본 약관 <제 5조>에 정한 휴대전화 인증 절차 기반으로, 만 14세 미만의 사용자가 본 서비스를 이용하고자 할 경우에는 부모 등 법정대리인의 동의를 얻어 회원가입이 가능하도록 조치하고 있습니다.
  
  
  제 3장. 보상 정책
  
  제 10조. 포인트의 적립 및 사용
  1) 회사는 회원이 폐건전지 수거함을 통해 적립한 자원에 따라 포인트를 지급하여 보상합니다.
  2) 수거함 적립에 대한 포인트의 보상방법은 아래와 같습니다.
  ① 적립 포인트 = 자원 투입 개수 x 회사(혹은 제휴사)가 지정한 적립률
  - 적립률은 자원의 종류, 시기, 지역 및 제휴사 정책 등에 따라 차등해 제공될 수 있습니다.
  - 일일 투입 가능한 자원의 최대 개수는 시기, 지역, 제휴사 정책 등에 따라 차등해 제공될 수 있습니다.
  ② 회사는 회원 1명당 일일 투입 가능한 자원의 최대 개수를 수거함 이용 시 확인 가능하도록 부착/홍보하고, 동시에 고객센터 문의 및 민원상담 등의 과정에서 지속적으로 안내하여 회원의 이용에 불편함이 없도록 노력합니다.
  ③ 회원이 수거함을 이용하며 일일 투입 개수를 초과하였을 경우, 회사는 초과 투입한 자원에 대해서는 절대 추가로 보상하지 않습니다. 또, 이미 회수된 자원에 대해서도 별도의 보상을 지급하지 않습니다.
  ④ 포인트는 회사 및 제휴사의 정책에 따라 적립이 불가할 수 있으며, 정해진 기간(일별/월별)에 따라 최고 한도가 정해질 수도 있습니다.
  3) 그 외에도 회사가 주관하는 각종 프로그램, 관련 문화활동, 이벤트 및 프로모션 등 다양한 마케팅 활동에 따라 포인트를 지급할 수 있습니다. 이 때, 포인트가 아닌 다른 보상기준이 적용될 수 있습니다.
  4) 회사는 회사 및 제휴사의 정책에 의거, ‘기부’ 등의 목적으로 적립된 포인트를 제공하기로 한 경우, 자원을 적립한 개인에게 직접 보상하지 않고 지자체 및 지정 단체 등에 기부하는 방법을 선택할 수 있습니다. 이 경우 역시 수거함 이용 시 확인 가능하도록 부착/홍보하고, 고객센터 문의 및 민원상담 등의 과정에서 지속적으로 안내하여 회원의 이용에 불편함이 없도록 노력합니다.
  5) 현금 환전 가능 포인트는 회사가 정한 기준에 따라 최소 금액 10p(포인트) 이상으로 정하며, 10원 단위까지 현금으로 전환이 가능합니다. 단, 현금 환전 가능 포인트는 회사의 정책 및 시장 상황에 따라 변경될 수 있습니다.
  6) 현금 환전은 본인 계정으로 보유한 포인트만 가능합니다. 따라서, 본인 외 가족 및 지인 등의 요청에 따른 현금 환전은 절대 불가합니다. 본 약관 <제 7조> 및 <제 23조>에 의거, 부정사용 및 부정적립을 통한 부당이득이 발생한 경우 회사는 회원 및 관련 이용자(협조 및 동조자)에게 민·형사상의 책임을 요구할 수 있습니다.
  
  제 11조. 포인트 양도 및 승계
  1) 회원이 적립한 포인트는 원칙적으로 양도 및 승계가 불가합니다. 단, 본 약관 <제 5조 3)항>에 해당하는 경우만 예외 적용합니다.
  2) 특히, 본 약관 <제 7조>의 이유로 회원의 자격상실 및 정지가 된 경우에는 어떠한 이유로도 양도 및 승계가 불가하며, 정상적인 회원의 권한이 복원된 경우에만 포인트 사용이 가능합니다.
  
  제 12조. 포인트 강제 소멸 및 제재
  1) 회사는 본 약관 <제 6조>의 방법으로 탈퇴하는 회원의 모든 포인트 및 기존 정보를 소멸합니다. 이 때 본 약관 <제 10조 5)항>에 의거, ‘현금 환전 가능 포인트’ 미만의 포인트 역시 모두 소멸됩니다. 단, 본 약관 <제 27조 3)항>에 의거, 회원이 현금 전환한 내역은 5년간 보관이 가능합니다.
  2) 회사는 본 약관 <제 7조>의 이유로 자격상실 통보를 받은 회원의 모든 포인트를 통보일을 기준으로 강제 소멸할 수 있으며, 회원은 이에 대한 어떠한 권리도 주장할 수 없습니다.
  3) 회사는 본 약관 <제 7조>에 해당하는 부정전환, 부정적립 포인트에 대해서는 통보와 동시에 강제 소멸할 수 있으며, 회원은 이에 대한 어떠한 권리도 주장할 수 없습니다.
  
  
  제 4장. 서비스 정책
  
  제 13조. 서비스 제공
  회사는 폐건전 수거함을 설치/운영함과 동시에 관련한 서비스를 회원에게 제공합니다. 회사가 제공하는 서비스는 홈페이지 개발/운영, 고객상담 및 민원접수, 민원해결, 각종 프로그램 및 문화 활동 운영 등이 포함됩니다.
  
  제 14조. 제공 서비스의 범위
  회사는 보다 나은 서비스를 제공하기 위해 아래와 같은 서비스를 제공하고 있습니다.
  1) 폐건전지 수거함과 운영서비스 : 수거함을 설치/운영하는 일련의 서비스를 제공합니다.
  2) 홈페이지 : www.punic.kr 통한 사업소개, 회원가입, 포인트 정보 및 현금전환 등을 제공합니다.
  3) 고객센터 : 070-8959-2960 을 통해 고객문의를 접수하고 요청한 민원을 해결하는 서비스를 제공합니다.
  4) 각종 문화활동 : 회원 및 비회원의 수거함 체험 및 교육, 연관 활동을 위한 프로그램들을 운영합니다.
  5) 이벤트 및 프로모션 : 회사가 제휴사 및 관련 파트너와 함께 다양한 이벤트 및 프로모션을 기획, 운영합니다.
  6) 기타 : 그 외 폐건전지 회수에 대한 목적 달성, 체험 교육, 강의 등 다양한 서비스를 제공할 수 있습니다.
  
  제 15조. ID 및 비밀번호
  1) 정회원은 본 약관 <제 5조>에 명시된 회원가입 절차를 통해 ID와 비밀번호를 등록해야 합니다. 준회원은 퓨닉 이용 시 사용한 휴대전화 번호를 ID로 사용하며, 추후 회원가입 절차를 통해 정식 ID와 비밀번호를 추가 등록해야 정회원으로 등록됩니다.
  2) 회원은 자신의 ID나 비밀번호를 제3자에게 공개하거나 이용을 허락해서는 안됩니다. 회원은 예기치 않은 이유로 ID 및 비밀번호를 타인에게 도용 당하거나 제3자가 사용하고 있음을 인지한 경우에는 지체없이 회사에 통보하여 그 안내에 따라야 합니다.
  3) 회사는 귀책사유 없이 회원이 자신의 ID나 비밀번호를 도용 당해 발생한 손해에 대해서는 법적 책임을 부담하지 않습니다.
  
  제 16조. 회사의 의무
  1) 회사는 회원이 원활한 서비스를 제공받을 수 있도록 최선을 다합니다.
  2) 회사는 회원의 퓨닉 위치 찾기, 퓨닉 사용가능 상태 확인, 포인트 적립, 포인트 현금 환전, 개인정보 변경 등 서비스와 연관된 정보를 제공합니다.
  3) 단, 회사는 아래의 경우 홈페이지 서비스의 일부 또는 전부를 중지하거나 제한할 수 있습니다.
  ① 서버/시스템 등 정보통신설비의 점검, 고장, 보수 및 교체, 통신 두절 등 부득이한 사유가 발생한 경우
  ② 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지한 경우
  ③ 인터넷 및 이동통신사업자의 서비스 장애로 인해 서비스 제공이 불가한 경우
  ④ 제반 설비의 장애 및 이용량 폭주, 정전, 해킹 방어 등으로 정상적인 서비스 제공에 지장이 있는 경우
  ⑤ 국가 비상 사태 및 천재지변 등의 불가항력적 사유가 발생한 경우
  4) 회사는 본 약관 <제 16조 3)항>에 의해 서비스 이용을 제한하거나 중지한 때에는 그 사유 및 기간 등을 홈페이지 및 공식 SNS 등에 게시하는 방법을 통해 회원에게 알려야 합니다.
  
  제 17조. 회원의 의무
  1) 회원은 관련 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 안됩니다.
  2) 회원은 회사의 서비스를 이용함에 있어 각 호의 행위를 해서는 안됩니다.
  ① 본 약관 및 서비스 이용에 관련된 관계법령을 위반한 행위
  ② 서비스를 제공받기 위해 이용 신청 또는 변경 신청 시 허위 사실을 기재하거나 타인의 정보를 도용하는 등 정상적인 서비스 운영을 고의로 방해하는 일체의 행위
  ③ 회사가 정상적으로 제공하는 방법이 아닌 다른 방법으로 회사가 보유하고 있는 정보를 탈취, 저장, 공개 및 부정한 목적으로 사용하는 행위
  ④ 회사의 지식재산권, 제3자의 지식재산권 등 기타 권리를 침해하거나 회사의 동의없이 회원 또는 제3자의 상업적인 목적을 위하여 본 서비스 구성요소의 전부 또는 일부의 내용을 복사, 복제, 판매, 재판매 또는 양수도 하는 행위
  ⑤ 기타 범죄 또는 법령이 금지하는 행위와 관련되었다고 의심받을 수 있는 일체의 행위
  ⑥ 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염자료를 등록 또는 유포하는 행위
  ⑦ 회사의 서비스를 해킹하거나 해킹에 준하는 접근, 위조, 변조 및 삭제 등 일체의 행위
  ⑧ 기타 불법적이거나 부당한 행위
  3) 회원은 반드시 본인이 직접 서비스를 이용해야 하며, 전자적장치를 타인에게 양도 또는 대여하는 등의 방법으로 타인으로 하여금 서비스를 이용할 수 있도록 해서는 안됩니다. 이의 위반으로 인한 책임은 전적으로 회원 본인에게 있습니다.
  
  제 18조. 저작권의 귀속 및 이용제한
  1) 회사가 작성한 저작물에 대한 저작권 및 기타 지적재산권은 모두 회사에 귀속합니다.
  2) 회원 및 이용자는 회사의 홈페이지 및 모바일 앱, 공식 SNS 등을 통해 얻은 정보 및 저작물 등을 회사의 사전 승낙없이 복제, 송신, 출판, 배포, 방송 및 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 해서는 안됩니다.
  3) 회원 및 이용자가 회사의 홈페이지, 공식 SNS 등에 게시한 게시물 및 그 내용에 대한 권리와 책임은 당사자에게 있습니다.
  4) 회사는 홈페이지, 공식 SNS에 게시된 이용자 게시물 중 불법적이거나 통상적인 미풍양속에 저해되는 경우, 타인, 제품, 서비스를 비방/비난하는 경우 등에는 별도의 동의없이 삭제 등의 조치를 취할 수 있습니다.
  
  제 19조. 광고 및 맞춤형 정보의 제공
  1) 회사는 서비스의 운영과 관련하여 광고성 정보를 제공할 수 있습니다.
  2) 회사는 광고성 정보 및 회원 맞춤형 정보를 문자 메시지 형식으로 제공할 수 있으며, 이는 광고성 정보에 대한 사전 수신 동의가 있는 경우 등 법령상 허용된 경우에 한하여 제공됩니다.
  
  제 20조. 위치정보 서비스
  회사는 위치정보의 보호 및 이용 등에 관한 법률(이하 “위치정보보호법”)에 의거하여 위치정보보호법, 정보통신망법, 전기통신기본법, 전기통신사업법 등 관계 법령을 준수하며, 개인위치정보주체와의 권리, 의무 및 책임사항을 준수합니다.
  
  
  제 5장. 고객지원 정책
  
  제 21조. 고객지원 서비스
  1) 회사는 회원의 문의사항 및 각종 민원의 해결, 서비스 개선을 위한 정당한 의견 제시, 관련 불만사항의 피해 확인 및 보상 처리를 위해 별도의 고객센터를 설치/운영합니다.
  2) 회사는 회원이 제출한 민원제기 및 불만사항, 의견 등을 우선적으로 처리합니다. 단, 신속한 처리가 곤란한 사유가 발생할 경우, 회사는 회원에게 그 사유와 처리일정을 통보합니다.
  
  제 22조. 고객응대 근로자 보호의무
  1) 회사는 산업안전보건법 <제 41조>에 의거, 고객응대 근로자 보호의무에 의해 최선을 다합니다.
  ① 회사의 고객응대 근로자는 고객센터를 운영하는 상담직원과 ㅁㅁㅁ 운영 직원 및 관계사 직원을 포함합니다.
  ② 상담직원에게 폭언, 욕설, 인격침해, 협박, 업무방해 등을 할 경우 즉시 상담을 종료할 수 있습니다. 이와 같은 조치에도 상황이 반복/지속될 경우, 회사는 본 약관 <제 7조>에 의거, 회원의 자격을 상실/정지시키고 이에 따른 후속조치를 취할 수 있습니다.
  ③ ㅁㅁㅁ 운영 직원 및 관계사 직원에게 폭언, 욕설, 인격침해, 폭행 등 고의로 업무방해를 한 경우 회사는 본 약관 <제 7조>에 의거, 회원의 자격을 상실/정지시키고 이에 따른 후속조치를 취할 수 있습니다.
  2) 회사는 근로기준법 <제 54조>에 의거, 상담직원, 퓨닉 운영 직원 및 관계사 직원의 휴게시간을 아래와 같이 부여합니다. 따라서, 아래의 시간에는 고객응대가 불가합니다.
  ① 상담직원 : (평일 기준) 13시 00분 ~ 14시 00분 (휴일 및 법정 공휴일은 휴무)
  ② 퓨닉 운영 직원 및 관계사 직원 : (평일 기준) 근무지역에 따라 휴게시간 및 휴무일 상이
  
  제 23조. 부적절한 사용에 대한 책임
  1) 회사는 본 약관 <제 7조>에 의거, 아래의 부적절한 사용에 대하여 회원 및 관련 이용자(협조 및 동조자)에게 민·형사상의 책임을 요구할 수 있습니다.
  ① 타인의 계정으로 포인트를 적립하거나 타인 계정의 현금 환전을 시도한 경우 (부정사용)
  ② 허용되지 않는 부정한 방법으로 회사의 서비스를 이용하거나 포인트를 적립한 경우 (부정적립)
  - 회사의 기기 및 시스템을 해킹한 경우
  - 시스템 오류 및 알려지지 않은 버그 등을 이용해 반복적으로 포인트를 적립한 경우
  - 1개의 자원으로 2건 이상의 포인트를 적립한 경우 등
  ③ 상기 부정사용 및 부정적립을 통한 부당이득이 발생한 경우
  ④ 회원이 고의로 회사의 자산을 훼손하거나 기기 고장을 유발하는 행위를 반복한 경우
  - 회사가 운영하는 기기의 전체 및 일부, 관련 부속품 등을 손·망실한 경우
  - 쓰레기 및 오물 등을 회사의 기기에 투입하여 고장을 유발하는 경우
  - 기기의 전원부 및 인터넷 연결부를 훼손하여 정상적인 기기동작 및 운영을 방해한 경우 등
  
  제 24조. 분쟁의 조정 및 해결
  1) 회사는 회원과 발생한 분쟁에 대해 공정거래위원회 또는 시도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
  2) 위의 조정에도 불구하고 해결이 불가한 경우에는 본 약관 <제 31조>의 방법으로 해결할 수 있습니다.
  
  
  제 6장. 개인정보의 수집 및 보호
  
  제 25조. 개인정보 수집
  1) 회사는 원활한 서비스 제공을 위해 회원가입 시 동의한 목적과 본 약관 <제 26조>에 명시한 범위 내에서만 개인정보를 수집, 이용하며, 관련한 상세 내용은 회사의 “개인정보처리방침(개인정보 수집 및 이용 동의서)”에서 확인할 수 있습니다.
  2) 회사는 개인정보를 수집하고자 하는 경우 회원에게 그 목적을 고지하며, 정보통신망 이용촉진 및 정보보호 등에 관한 법률에 따라 “개인정보 수집 및 이용 동의”(만 14세 미만 회원의 경우 그 법정대리인의 동의 포함)를 받습니다. 회원은 언제든지 이를 철회할 수 있습니다.
  
  제 26조. 개인정보 이용 및 제공
  회사는 수집한 개인정보를 “개인정보 수집 및 이용 동의서”에 기재된 목적 범위를 넘어선 용도로 이용하거나 기재된 자 이외의 제3자에게 제공할 수 없습니다. 단, 아래의 경우에는 예외로 합니다.
  ① 회원의 포인트 현금 환전 및 정산을 위해 추가로 정보 확인이 필요한 경우
  ② 회사가 상품/기념품 등의 배송을 위해 배송에 필요한 회원정보(성명, 주소, 연락처 등)를 배송업체에 제공하는 경우
  ③ 정부기관, 법률 및 관련 규정에 의해 특정 회원의 정보를 제공해야 할 중대한 사유가 발생한 경우
  ④ 회사와 관련한 인수, 합병, 포괄적 영업양도 등이 발생한 경우
  ⑤ 본 약관 <제 7조>와 관련하여 회원 자격을 상실한 기존 회원의 서비스 제한을 해야 할 경우
  ⑥ 회사가 광고/마케팅 활동, 정부 및 학계의 통계작성 연구 등의 목적으로 특정 개인을 알아볼 수 없는 형태로 가공(비식별 개인정보 및 ADID)해 사용하는 경우 등
  
  제 27조. 개인정보 보유 및 이용기간
  1) 회사는 수집한 개인정보를 회원자격을 유지한 기간동안 보유합니다.
  2) 회사는 회원 탈퇴 시 보유한 회원 개인정보 일체를 지체없이 파기합니다.
  3) 회사는 관련 법률에 의거하여 아래의 개인정보는 일정기간 보유합니다.
  ① 웹사이트 방문 기록 : 3개월 보관 (통신비밀보호법)
  ② 본인확인에 관한 기록 : 6개월 보관 (정보통신망법)
  ③ 표시/광고에 관한 기록 : 6개월 보관 (전자상거래법)
  ④ 계약 또는 청약 철회 등에 관한 기록: 5년 보관 (전자상거래법)
  ⑤ 대금결제 및 재화 등의 공급에 관한 기록: 5년 보관 (전자상거래법)
  ⑥ 소비자의 불만 또는 분쟁처리에 관한 기록: 3년 보관 (전자상거래법)
  ⑦ 전자금융에 관한 기록: 5년 보관 (전자금융거래법)
  4) 그 외 파기, 제3자 제공 및 위탁 등 개인정보와 관련한 상세 내용은 회사의 “개인정보처리방침(개인정보 수집 및 이용 동의서)”에서 확인할 수 있습니다.
  
  제 28조. 위치정보의 이용
  회사는 아래의 기능 제공을 위해 위치기반 서비스를 이용하나, 회원의 위치 정보 수집은 없는 지도 서비스 제공만을 위해 사용합니다.
  ① 퓨닉 위치 찾기
  
  제 7장. 이용약관 일반
  
  제 29조. 서비스의 변경 및 중지
  1) 회사는 아래와 같은 상황 발생시 서비스의 내용을 변경 또는 중지할 수 있습니다.
  ① 영업 양도 및 합병, 분할 등 회사의 주 사업방향에 변화가 발생하는 경우
  ② 대표자 및 주요 경영진 변경 등 회사 주요 인력의 변화가 발생하는 경우
  ③ 시장 수익모델의 변화, 영업부문 폐지/통합 등 서비스 지속이 불가한 중대한 차질이 발생하는 경우
  ④ 회사에 발생하는 부득이한 사정으로 인해 사업을 영위할 수 없을 경우 등
  2) 회사는 서비스 중지가 일시에 마무리되지 않을 경우, 모든 서비스를 종료할 수 있습니다. 회사는 서비스 종료일 3개월 이전에 <제 3조 3)항 ③호>에 규정된 방법을 준용하여 회원에게 공지해야 합니다.
  3) 회사가 보유한 모든 개인정보 및 각종 기록, 회원 보상 포인트 등은 서비스 종료일에 일괄적으로 소멸됩니다. 따라서, 서비스 종료일 이후에는 회사 및 제휴사를 통한 포인트 적립이 불가하며, 서비스 종료와 함께 소멸된 포인트는 복구되지 않습니다.
  
  제 30조. 손해배상
  1) 아래와 같은 상황이 발생한 경우, 회원은 회사에 손해를 배상할 책임이 있습니다.
  ① 회원이 본 이용약관의 의무를 위반하여 회사에 손해를 입힌 경우
  ② 회원이 회사의 서비스의 이용과 관련하여 회사에 손해를 입힌 경우
  ③ 기타 회사의 저작물 및 저작권, 특허권 등을 침해하여 손해를 입힌 경우
  2) 회사는 회원이 입힌 손해를 객관적으로 증명하여 청구하거나 관계법령 및 상관례에 따라 소송을 제기할 수 있습니다.
  
  제 31조. 분쟁의 조정 및 해결
  1) 회사는 회원과 분쟁이 발생할 경우, 회원이 제기하는 정당한 의견이나 불만을 반영하여 적절하고 신속한 조치를 취합니다. 단, 신속한 처리가 곤란한 사유가 발생할 경우 회사는 회원에게 그 사유와 처리일정을 통보합니다.
  2) 회사는 회원과 발생한 분쟁에 대해 공정거래위원회 또는 시도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
  3) 회사는 회원 및 제휴고객, 기타 이용자와 소송이 필요한 경우 회사의 본점을 관할하는 법원을 관할 법원으로 하며, 본 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 대한민국 관계법령 및 상관례에 따릅니다.
  
  
  부칙. 공고 및 시행일자
  1) 본 이용약관은 아래와 같이 공고 후 시행하며, 시행일자로부터 즉시 효력이 발생됩니다.
  ① 공고일 : 2022년 3월 4일
  ② 시행일 : 2022년 3월 4일
  2) 본 이용약관 시행일 이전에 적용되던 회사의 이용약관은 이 약관으로 대체됩니다.
  
  
  
  
  
  개인정보 수집 및 이용 동의
  
  1. 총칙
  1) ㈜쿳션(이하 “회사”)은 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”), "개인정보보호법" 등 국내의 개인정보 관련 법률을 준수하며, 서비스 이용자(이하 “회원”)의 개인정보를 철저히 보호하고 있습니다.
  2) 회사는 회원의 개인정보를 적극적으로 보호하기 위하여 개인정보처리방침을 제정하고 이를 준수하고 있습니다. 개인정보처리방침을 통해 회원의 개인정보가 어떤 목적으로 수집되고, 어떠한 방법으로 보호되는지 공개하고 있습니다.
  3) 회사의 개인정보처리방침은 관계 법령 및 지침, 시장 환경 및 변화, 회사 내부 운영방침의 변경에 따라 수정, 삭제 및 보완될 수 있습니다. 개인정보처리방침의 변동 사항이 발생할 경우, 관계 법령 및 지침이 정하는 방법에 의거, 고지 후 시행합니다.
  
  2. 개인정보 이용 목적
  1) 회원의 가입 및 탈퇴 의사 확인, 고객지원 및 민원처리를 위한 이용자 식별, 만 14세 미만 이용자의 보호자(법정대리인)의 동의 및 보호자 확인 등 회사가 제공하는 원활한 서비스 제공 및 민원/상담을 위해 회원의 개인정보를 이용합니다.
  2) 회사가 제공하는 제품 및 서비스의 품질확인, 서비스의 보상정책(포인트 및 기타) 확인 및 전환, 개선요구 및 의견청취, 설문 답변 등 회원이 제기하는 각종 민원에 대한 구체적인 내용 확인을 위해 개인정보를 이용합니다.
  3) 회사 및 관련 사업자(파트너)의 이벤트 정보 제공 및 참여 확인, 광고성 정보 제공, 개인화 콘텐츠 제공 등 회사가 주최/주관하는 다양한 마케팅 및 프로모션 목적으로 개인정보를 이용합니다.
  4) 대한민국의 관계 법령 및 회사의 이용약관을 위반하는 회원에 대한 이용 제한, 부정이용 행위 등 회사의 서비스 운영에 지장을 주거나 타 이용자의 사용을 방해하는 행위에 대한 방지 및 제재를 위해 개인정보를 이용합니다.
  5) 그 밖에 이용약관 개정 및 서비스 정책 변경 등 회원 대상의 고지사항 전달, 분쟁조정을 위한 기록 보존, 각종 민원처리 결과에 대한 제재 조치 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.
  
  3. 개인정보 수집 항목
  1) 회사는 다양하고 편리한 서비스를 제공하기 위해 회원가입 시 아래의 개인정보를 수집하고 있습니다.
  ① 필수항목 : 휴대전화번호, 이름, 아이디, 비밀번호, 서비스 이용 기록 및 접속기록(log)
  ② 선택항목 : 주소, 은행계좌 정보(은행명, 계좌번호, 예금주명)
  3) 회원의 고객지원 및 민원/상담을 처리(해결)하는 과정에서 아래 개인정보(전부 및 일부)를 추가적으로 수집할 수 있습니다. 이 경우 사전에 회원에게 구두 동의를 확보하고, 녹취 등의 방법으로 기록합니다.
  ① 홈페이지 (1:1문의하기) : 이름, 휴대전화번호 등
  ② 고객센터 (070-8959-2960) : 이름, 휴대전화번호, 서비스 이용 기록, 온라인 접속기록(log), 은행계좌 정보(은행명, 계좌번호, 예금주명), 환전 신청기록 및 기타 등
  
  4. 개인정보 보유, 이용 및 파기
  1) 회사는 회원 탈퇴 시 보유한 회원 개인정보 일체를 지체없이 파기합니다.
  2) 단, 서비스 부정이용 행위 및 타 사용자의 이용을 방해한 이력이 있는 사용자 경우, 반복되는 피해 방지를 위해 회원 탈퇴 처리 후 관련 기록(이름, 휴대전화번호, 서비스 사용이력 및 제재 사항 등)을 아래의 법률(전자상거래법, 소비자의 불만 또는 분쟁처리에 관한 기록)에 의거, 3년 간 보관합니다.
  3) 전자상거래 등에서의 소비자 보호에 관한 법률, 전자금융거래법, 통신비밀보호법, 정보통신망법 등 법령에서 일정기간 정보의 보관을 규정하는 법률에 의하여 아래와 같은 사항은 일정기간 보유합니다.
  ① 웹사이트 방문 기록 : 3개월 보관 (통신비밀보호법)
  ② 본인확인에 관한 기록 : 6개월 보관 (정보통신망법)
  ③ 표시/광고에 관한 기록 : 6개월 보관 (전자상거래법)
  ④ 계약 또는 청약 철회 등에 관한 기록: 5년 보관 (전자상거래법)
  ⑤ 대금결제 및 재화 등의 공급에 관한 기록: 5년 보관 (전자상거래법)
  ⑥ 소비자의 불만 또는 분쟁처리에 관한 기록: 3년 보관 (전자상거래법)
  ⑦ 전자금융에 관한 기록: 5년 보관 (전자금융거래법)
  4) 개인정보 파기 방법은 아래와 같습니다.
  ① 종이에 출력된 개인정보: 분쇄 혹은 소각
  ② 전자적 파일 형태로 저장된 개인정보: 복구 및 재생이 되지 않는 기술적인 방법을 사용하여 삭제
  
  5. 개인정보 제3자 제공 및 위탁
  1) 회사는 원칙적으로 이용자 동의 없는 개인정보를 외부에 제공하지 않습니다. 단, 회원이 사전에 동의하거나 관계 법령에서 정한 절차와 방법에 따라 수사기관이 개인정보 제공을 요구하는 아래의 경우에는 예외적으로 제공할 수 있습니다.
  2) 회사는 서비스 제공을 위해 필요한 업무 중 일부를 외부 업체에 위탁하고 있으며, 위탁 받은 업체가 정보통신망법에 따라 회원의 개인정보를 안전하게 처리하도록 필요한 사항을 규정하고 관리/감독하고 있습니다.
  수탁업체	위탁업무 내용	개인정보의 보유 및 이용기간
  문자메시지 발송 및 관리
  정보/광고 문자 발송	계약기간 내 이용
                                      계약 종료시 정보일괄 삭제
  회원 가입 시 휴대폰 인증
  ID/비밀번호 분실 시 본인 인증    계약기간 내 이용
                                           계약 종료시 정보일괄 삭제
  회원 가입 시 휴대폰 인증
  만 14세 미만 가입자의 법정대리인 인증 절차     계약기간 내 이용
                                                             계약 종료시 정보일괄 삭제
  
  6. 위치정보의 이용 및 보호
  1) 회사는 위치정보의 보호 및 이용 등에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전기통신기본법, 전기통신사업법 등 관계 법령을 준수하며, 개인위치정보주체와의 권리, 의무 및 책임사항을 준수합니다.
  
  7. 개인정보의 열람, 정정, 탈퇴 및 동의 철회 방법
  1) 회사의 회원은 홈페이지를 통해 자신의 개인정보를 열람하거나 정정, 탈퇴를 할 수 있습니다.
  2) 회사의 회원은 언제든지 ‘회원탈퇴’ 메뉴를 통해 개인정보의 수집 및 이용 동의를 철회할 수 있습니다.
  3) 회사의 회원은 탈퇴 시 포인트 소멸, 포인트 환전 및 복구 불가, 개인정보 삭제 및 복구 불가에 대해 동의 확인 후 탈퇴할 수 있습니다. 회원 탈퇴 후 삭제된 개인정보의 복구는 불가합니다.
  
  8. 쿠키의 운영 및 활용
  1) 쿠키(Cookie)는 회원이 홈페이지를 접속할 때 회원의 사용한 웹 브라우저를 통해 회원의 PC에 하드디스크 드라이브에 저장하는 매우 작은 크기의 텍스트 파일입니다. 회원이 홈페이지를 재방문할 경우 홈페이지 서버(Server)는 회원PC에 저장된 쿠키의 내용을 읽어 회원이 설정한 서비스 이용 환경을 유지할 수 있습니다.
  2) 회사는 저장된 쿠키 정보(방문 정보, 서비스 접속 시간 및 빈도, 서비스 이용과정 중 생성 및 입력한 정보)를 분석하여 이용자의 취향과 관심에 특화된 콘텐츠 및 서비스 혹은 광고 등을 제공할 수 있습니다.
  3) 회원은 웹브라우저 옵션을 이용하여 항상 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 쿠키의 저장을 거부할 수 있습니다. 단, 쿠키의 저장을 거부할 경우 회사에서 제공하는 일부 서비스 이용에 어려움이 있을 수 있습니다.
  
  9. 개인정보보호를 위한 기술적, 관리적 대책
  회사는 회원의 소중한 개인정보보호를 위해 다음의 노력을 하고 있습니다.
  1) 회원의 개인정보를 암호화하여 보관하며 암호화된 통신구간을 이용하여 전송하고, 비밀번호 및 포인트와 같은 중요정보는 암호화하여 보관합니다.
  2) 해킹이나 컴퓨터 바이러스로부터 보호하기 위해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을 설치하고 있습니다. 해커 등의 침입을 탐지하고 차단할 수 있는 시스템을 설치하여 24시간 감시하고 있으며, 백신 프로그램을 설치하여 시스템이 최신 악성코드나 바이러스에 감염되지 않도록 노력하고 있습니다. 또한 새로운 해킹/보안 기술에 대해 지속적으로 연구하여 서비스에 적용하고 있습니다.
  3) 개인정보를 처리하는 모든 임직원들을 대상으로 개인정보보호 의무와 보안에 대한 정기적인 교육과 캠페인을 실시하고 있습니다.
  4) 회원의 소중한 개인정보에 접근할 수 있는 담당자를 최소화하며, 회원의 개인정보를 보관하는 데이터베이스(Database) 시스템과 개인정보를 처리하는 시스템에 대한 비밀번호의 생성과 변경, 그리고 접근할 수 있는 권한에 대한 체계적인 기준을 마련하고 지속적인 감사를 실시하고 있습니다.
  5) 회사는 개인정보유출에 대한 만일의 사태가 발생할 경우에 대비하여 정보통신망법 시행령 개정안에서 요구하는 개인정보보호 손해배상보험에 가입, 회원의 피해를 최소화할 수 있는 방안을 마련하고 있습니다.
  
  10. 개인정보보호 책임자 안내
  회사는 이용자의 개인정보 관련 문의사항 및 불만 처리 등을 위하여 아래와 같이 개인정보보호 책임자를 지정하고 있습니다.
  
  [개인정보보호 책임자]
  - 전 화 : 070-8959-2960
  - 이메일 : cutshion.com
  
  11. 개인정보 침해 신고 및 상담
  기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에는 아래 기관으로 문의해 주십시오.
  
  [개인정보 침해 신고 및 상담]
  - 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)
  - 대검찰청 사이버수사 (www.spo.go.kr / 국번없이 1301)
  - 경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182)
  
  
  부칙. 공고 및 시행일자
  1) 본 개인정보처리방침은 아래와 같이 공고 후 시행하며, 시행일자로부터 즉시 효력이 발생됩니다.
  ① 공고일 : 2022년 3월 4일
  ② 시행일 : 2022년 3월 4일
  2) 본 개인정보처리방침 시행일 이전에 적용되던 회사의 개인정보처리방침은 이 방침으로 대체됩니다.
  
  
  
  `;
}

export function policy() {
  return `퓨닉 개인정보처리방침

  1. 총칙
  
  1) (주)쿳션(이하 “회사”)은 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”), "개인정보보호법" 등 국내의 개인정보 관련 법률을 준수하며, 퓨닉 서비스 이용자(이하 “회원”)의 개인정보를 철저히 보호하고 있습니다.
  
  2) 회사는 회원의 개인정보를 적극적으로 보호하기 위하여 개인정보처리방침을 제정하고 이를 준수하고 있습니다. 개인정보처리방침을 통해 회원의 개인정보가 어떤 목적으로 수집되고, 어떠한 방법으로 보호되는지 공개하고 있습니다.
  
  3) 회사의 개인정보처리방침은 관계 법령 및 지침, 시장 환경 및 변화, 회사 내부 운영방침의 변경에 따라 수정, 삭제 및 보완될 수 있습니다. 개인정보처리방침의 변동 사항이 발생할 경우, 관계 법령 및 지침이 정하는 방법에 의거, 고지 후 시행합니다.
  
  2. 개인정보 이용 목적
  
  1) 회원의 가입 및 탈퇴 의사 확인, 고객지원 및 민원처리를 위한 이용자 식별 등 회사가 제공하는 원활한 서비스 제공 및 민원/상담을 위해 회원의 개인정보를 이용합니다.
  
  2) 회사가 제공하는 제품 및 서비스의 품질확인, 서비스의 보상정책(포인트 및 기타) 확인 및 전환, 개선요구 및 의견청취, 설문 답변 등 회원이 제기하는 각종 민원에 대한 구체적인 내용 확인을 위해 개인정보를 이용합니다.
  
  3) 회사 및 관련 사업자(파트너)의 이벤트 정보 제공 및 참여 확인, 광고성 정보 제공, 개인화 콘텐츠 제공 등 회사가 주최/주관하는 다양한 마케팅 및 프로모션 목적으로 개인정보를 이용합니다.
  
  4) 대한민국의 관계 법령 및 회사의 이용약관을 위반하는 회원에 대한 이용 제한, 부정이용 행위 등 회사의 서비스 운영에 지장을 주거나 타 이용자의 사용을 방해하는 행위에 대한 방지 및 제재를 위해 개인정보를 이용합니다.
  
  5) 그 밖에 이용약관 개정 및 서비스 정책 변경 등 회원 대상의 고지사항 전달, 분쟁조정을 위한 기록 보존, 각종 민원처리 결과에 대한 제재 조치 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.
  
  3. 개인정보 수집 항목
  
  1) 회사는 다양하고 편리한 서비스를 제공하기 위해 회원가입 시 아래의 개인정보를 수집하고 있습니다.
  
  ① 필수항목 : 휴대전화번호, 이름, 아이디, 비밀번호, 서비스 이용 기록 및 접속기록(log)
  
  ② 선택항목 : 주소, 은행계좌 정보(은행명, 계좌번호, 예금주명)
  
  4) 회원의 고객지원 및 민원/상담을 처리(해결)하는 과정에서 아래 개인정보(전부 및 일부)를 추가적으로 수집할 수 있습니다. 이 경우 사전에 회원에게 구두 동의를 확보하고, 녹취 등의 방법으로 기록합니다.
  
  ① 홈페이지 (1:1문의하기) : 이름, 휴대전화번호, 전자우편(이메일) 주소 등
  
  ② 고객센터 : 이름, 휴대전화번호, 생년월일, 서비스 이용 기록, 온라인 접속기록(log), 은행계좌 정보(은행명, 계좌번호, 예금주명), 환전 신청기록 및 기타 등
  
  4. 개인정보 보유, 이용 및 파기
  
  1) 회사는 회원 탈퇴 시 보유한 회원 개인정보 일체를 지체없이 파기합니다.
  
  2) 단, 서비스 부정이용 행위 및 타 사용자의 이용을 방해한 이력이 있는 사용자 경우, 반복되는 피해 방지를 위해 회원 탈퇴 처리 후 관련 기록(이름, 휴대전화번호, 생년월일, 서비스 사용이력 및 제재 사항 등)을 아래의 법률(전자상거래법, 소비자의 불만 또는 분쟁처리에 관한 기록)에 의거, 3년 간 보관합니다.
  
  3) 전자상거래 등에서의 소비자 보호에 관한 법률, 전자금융거래법, 통신비밀보호법, 정보통신망법 등 법령에서 일정기간 정보의 보관을 규정하는 법률에 의하여 아래와 같은 사항은 일정기간 보유합니다.
  
  ① 웹사이트 방문 기록 : 3개월 보관 (통신비밀보호법)
  
  ② 본인확인에 관한 기록 : 6개월 보관 (정보통신망법)
  
  ③ 표시/광고에 관한 기록 : 6개월 보관 (전자상거래법)
  
  ④ 계약 또는 청약 철회 등에 관한 기록: 5년 보관 (전자상거래법)
  
  ⑤ 대금결제 및 재화 등의 공급에 관한 기록: 5년 보관 (전자상거래법)
  
  ⑥ 소비자의 불만 또는 분쟁처리에 관한 기록: 3년 보관 (전자상거래법)
  
  ⑦ 전자금융에 관한 기록: 5년 보관 (전자금융거래법)
  
  4) 개인정보 파기 방법은 아래와 같습니다.
  
  ① 종이에 출력된 개인정보: 분쇄 혹은 소각
  
  ② 전자적 파일 형태로 저장된 개인정보: 복구 및 재생이 되지 않는 기술적인 방법을 사용하여 삭제
  
  5. 개인정보 제3자 제공 및 위탁
  
  1) 회사는 원칙적으로 이용자 동의 없는 개인정보를 외부에 제공하지 않습니다. 단, 회원이 사전에 동의하거나 관계 법령에서 정한 절차와 방법에 따라 수사기관이 개인정보 제공을 요구하는 아래의 경우에는 예외적으로 제공할 수 있습니다.
  
  2) 회사는 서비스 제공을 위해 필요한 업무 중 일부를 외부 업체에 위탁하고 있으며, 위탁 받은 업체가 정보통신망법에 따라 회원의 개인정보를 안전하게 처리하도록 필요한 사항을 규정하고 관리/감독하고 있습니다.
  
  6. 개인정보의 열람, 정정, 탈퇴 및 동의 철회 방법
  
  1) 회사의 회원은 홈페이지를 통해 자신의 개인정보를 열람하거나 정정, 탈퇴를 할 수 있습니다.
  
  2) 회사의 회원은 언제든지 ‘회원탈퇴’ 메뉴를 통해 개인정보의 수집 및 이용 동의를 철회할 수 있습니다.
  
  3) 회사의 회원은 탈퇴 시 포인트 소멸, 포인트 환전 및 복구 불가, 개인정보 삭제 및 복구 불가에 대해 동의 확인 후 탈퇴할 수 있습니다. 회원 탈퇴 후 삭제된 개인정보의 복구는 불가합니다.
  
  8. 쿠키의 운영 및 활용
  
  1) 쿠키(Cookie)는 회원이 홈페이지를 접속할 때 회원의 사용한 웹 브라우저를 통해 회원의 PC에 하드디스크 드라이브에 저장하는 매우 작은 크기의 텍스트 파일입니다. 회원이 홈페이지를 재방문할 경우 홈페이지 서버(Server)는 회원PC에 저장된 쿠키의 내용을 읽어 회원이 설정한 서비스 이용 환경을 유지할 수 있습니다.
  
  2) 회사는 저장된 쿠키 정보(방문 정보, 서비스 접속 시간 및 빈도, 서비스 이용과정 중 생성 및 입력한 정보)를 분석하여 이용자의 취향과 관심에 특화된 콘텐츠 및 서비스 혹은 광고 등을 제공할 수 있습니다.
  
  3) 회원은 웹브라우저 옵션을 이용하여 항상 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 쿠키의 저장을 거부할 수 있습니다. 단, 쿠키의 저장을 거부할 경우 회사에서 제공하는 일부 서비스 이용에 어려움이 있을 수 있습니다.
  
  9. 개인정보보호를 위한 기술적, 관리적 대책
  
  회사는 회원의 소중한 개인정보보호를 위해 다음의 노력을 하고 있습니다.
  
  1) 회원의 개인정보를 암호화하여 보관하며 암호화된 통신구간을 이용하여 전송하고, 비밀번호 및 수퍼빈 포인트와 같은 중요정보는 암호화하여 보관합니다.
  
  2) 해킹이나 컴퓨터 바이러스로부터 보호하기 위해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을 설치하고 있습니다. 해커 등의 침입을 탐지하고 차단할 수 있는 시스템을 설치하여 24시간 감시하고 있으며, 백신 프로그램을 설치하여 시스템이 최신 악성코드나 바이러스에 감염되지 않도록 노력하고 있습니다. 또한 새로운 해킹/보안 기술에 대해 지속적으로 연구하여 서비스에 적용하고 있습니다.
  
  3) 개인정보를 처리하는 모든 임직원들을 대상으로 개인정보보호 의무와 보안에 대한 정기적인 교육과 캠페인을 실시하고 있습니다.
  
  4) 회원의 소중한 개인정보에 접근할 수 있는 담당자를 최소화하며, 회원의 개인정보를 보관하는 데이터베이스(Database) 시스템과 개인정보를 처리하는 시스템에 대한 비밀번호의 생성과 변경, 그리고 접근할 수 있는 권한에 대한 체계적인 기준을 마련하고 지속적인 감사를 실시하고 있습니다.
  
  5) 회사는 개인정보유출에 대한 만일의 사태가 발생할 경우에 대비하여 정보통신망법 시행령 개정안에서 요구하는 개인정보보호 손해배상보험에 가입, 회원의 피해를 최소화할 수 있는 방안을 마련하고 있습니다.
  
  10. 개인정보보호 책임자 안내
  
  회사는 이용자의 개인정보 관련 문의사항 및 불만 처리 등을 위하여 아래와 같이 개인정보보호 책임자를 지정하고 있습니다.
  
  [개인정보 보호책임자]
  
  - 전화 : 070-8959-2960
  
  - 메일 : cutshion.com
  
  11. 개인정보 침해 신고 및 상담
  
  기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에는 아래 기관으로 문의해 주십시오.
  
  [개인정보 침해 신고 및 상담]
  
  - 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)
  
  - 대검찰청 사이버수사 (www.spo.go.kr / 국번없이 1301)
  
  - 경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182)
  
  
  [부칙] 공고 및 시행일자
  
  1) 본 개인정보처리방침은 아래와 같이 공고 후 시행하며, 시행일자로부터 즉시 효력이 발생됩니다.
  
  ① 공고일 : 2022년 3월 4일
  
  ② 시행일 : 2022년 3월 4일
  
  2) 본 개인정보처리방침 시행일 이전에 적용되던 회사의 개인정보처리방침은 이 방침으로 대체됩니다.
  
  `;
}

export function draw() {
  return `
  회원탈퇴 전, 유의사항을 확인해 주시기 바랍니다.

  · 회원 탈퇴 시, 회사가 보유한 개인정보는 즉시 삭제되며, 보유한 포인트는 모두 소멸됩니다.

  · 포인트는 탈퇴와 동시에 즉시 소멸되므로, 잔여 포인트 환전 신청 및 환전 완료 후 탈퇴하시길 권장 드립니다. 
  단, 이용약관에 의거하여 1,000포인트 미만의 잔여 포인트는 환전이 불가하며, 보유한 포인트는 10원 단위까지 환전이 가능합니다.
  
  · 회원 탈퇴 후에는 보유한 개인정보가 모두 삭제되어 환전이 불가하며, 회사는 탈퇴한 회원의 포인트를 복구할 수 없음을 알려드립니다.

  · 회원 탈퇴 후에도 기존에 입력하신 1:1문의하기 및 상담 채널로 문의하셨던 내역은 전자상거래법에 의해 3년간 보관됩니다.
  `;
}

export function guardian() {
  return `
  - 만 14세 미만의 어린이는 법률에 의거하여 보호자(법적대리인)의 동의가 필요합니다.

  - 정보통신망이용촉진 및 정보보호등에 관한 법률 제31조 제1항에서 14세미만의 아동의 개인정보수집 시 부모의 동의를 얻도록 규정되어 있습니다. 동의 체크 후 부모님 동의 확인 인증을 진행해 주시기 바랍니다. 아이핀, 휴대폰 인증 시 신용평가 기관을 통하여 실명확인을 진행하며, 실명 확인 용도 외 별도 저장되지 않습니다.
  `;
}
