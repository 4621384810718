import axios from 'axios';
import { CheckSearchedWord } from './Formcheck';
// const server = 'http://farm.podosee.com';
// const server = 'https://punic.kr';
// const server = window.location.origin;
const server = 'http://test.punic.kr';

export async function RequestAxios(data, httpMethod, httpUrl, textCheck) {
  try {
    if (textCheck !== false) {
      textCheck = true;
    }

    const url = `${server}/api/${httpUrl}`;

    const method = httpMethod;

    const formData = new FormData();

    if (data) {
      for (let [key, val] of data.entries()) {
        if (textCheck) {
          if (CheckSearchedWord(val)) {
            if (key === 'attachFile') {
              val.length > 0 &&
                val.forEach(element => {
                  formData.append('attachFile', element);
                });
            } else if (key === 'bannerFile') {
              val.length > 0 &&
                val.forEach(element => {
                  formData.append('bannerFile', element);
                });
            } else if (key === 'detailImageFile') {
              val.length > 0 &&
                val.forEach(element => {
                  formData.append('detailImageFile', element);
                });
            } else {
              formData.append(key, val);
            }
          } else {
            throw '사용할수 없는 문자가 포함되어 있습니다.';
          }
        } else {
          if (key === 'attachFile') {
            val.length > 0 &&
              val.forEach(element => {
                formData.append('attachFile', element);
              });
          } else if (key === 'bannerFile') {
            val.length > 0 &&
              val.forEach(element => {
                formData.append('bannerFile', element);
              });
          } else if (key === 'detailImageFile') {
            val.length > 0 &&
              val.forEach(element => {
                formData.append('detailImageFile', element);
              });
          } else {
            formData.append(key, val);
          }
        }
      }
    }

    return await axios({
      method: method,
      url: url,
      responseType: 'json',
      headers: {
        'Content-Type': 'multipart/form-data; euc-kr',
        // if backend supports u can use gzip request encoding
        // "Content-Encoding": "gzip",
      },
      transformRequest: (data, headers) => {
        // !!! override data to return formData
        // since axios converts that to string
        return formData;
      },
      data: formData,
      onUploadProgress: function (progressEvent) {
        // console.log(progressEvent);
      },
    })
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log(err);
      });
    // .catch(e => {
    //   console.log(e);
    //   return 9999;
    // });
  } catch (error) {
    console.log(error);
    return 10000;
  }
}

export async function RequestAxios2(
  data,
  httpMethod,
  httpUrl,
  textCheck,
  progressFunction,
) {
  try {
    if (textCheck !== false) {
      textCheck = true;
    }
    const server = window.location.origin;
    const url = `${server}/api/${httpUrl}`;

    const method = httpMethod;

    const formData = new FormData();
    const progressFormData = new FormData();
    let attachFileInfo = {};
    let progressResult;

    if (data) {
      for (let [key, val] of data.entries()) {
        if (textCheck) {
          if (CheckSearchedWord(val)) {
            if (key === 'attachFile') {
              val.length > 0 &&
                val.forEach(element => {
                  attachFileInfo = element.type;
                  formData.append('attachFile', element);
                });
            } else {
              if (key === 'userSQ') {
                progressFormData.append(key, val);
              }
              formData.append(key, val);
            }
          } else {
            throw '사용할수 없는 문자가 포함되어 있습니다.';
          }
        } else {
          formData.append(key, val);
        }
      }
    }

    if (attachFileInfo.split('/')[1] != 'mpeg') {
      progressResult = setInterval(async () => {
        await axios({
          method: `POST`,
          url: `${url}/progress`,
          responseType: 'json',
          headers: {
            'Content-Type': 'multipart/form-data; euc-kr',
            // if backend supports u can use gzip request encoding
            // "Content-Encoding": "gzip",
          },
          transformRequest: (data, headers) => {
            // !!! override data to return formData
            // since axios converts that to string
            return progressFormData;
          },
          data: progressFormData,
          onUploadProgress: function (progressEvent) {
            // console.log(progressEvent);
          },
        })
          .then(response => {
            progressFunction(response.data.percent);

            // console.log(response.data.percent);
          })
          .catch(err => {
            console.log(err);
          });
      }, 5000);
    }

    return await axios({
      method: method,
      url: url,
      responseType: 'json',
      headers: {
        'Content-Type': 'multipart/form-data; euc-kr',
        // if backend supports u can use gzip request encoding
        // "Content-Encoding": "gzip",
      },
      transformRequest: (data, headers) => {
        // !!! override data to return formData
        // since axios converts that to string
        return formData;
      },
      data: formData,
      onUploadProgress: function (progressEvent) {
        if (attachFileInfo.split('/')[1] == 'mpeg') {
          progressFunction((progressEvent.loaded / progressEvent.total) * 100);
        }
      },
    })
      .then(response => {
        if (attachFileInfo.split('/')[1] != 'mpeg') {
          clearInterval(progressResult);
        }
        console.log(response);
        return response;
      })
      .catch(err => {
        if (attachFileInfo.split('/')[1] != 'mpeg') {
          clearInterval(progressResult);
        }
        console.log(err);
      });
    // .catch(e => {
    //   console.log(e);
    //   return 9999;
    // });
  } catch (error) {
    console.log(error);
    return 10000;
  }
}

export async function FileAxios(data, progress) {
  try {
    const url = `${server}/app/api/api`;
    const method = 'POST';

    const formData = new FormData();

    if (data) {
      for (let [key, val] of data.entries()) {
        formData.append(key, val);
      }
    }

    return await axios({
      method: method,
      url: url,
      responseType: 'json',
      headers: {
        'Content-Type': 'multipart/form-data',
        // if backend supports u can use gzip request encoding
        // "Content-Encoding": "gzip",
      },
      transformRequest: (data, headers) => {
        // !!! override data to return formData
        // since axios converts that to string
        return formData;
      },
      data: formData,
      onUploadProgress: function (progressEvent) {
        progress(progressEvent);
      },
    }).catch(e => {
      return 9999;
    });
  } catch (error) {
    console.log(error);
  }
}

export async function MailAxios(data) {
  const url = `${server}/app/api/mail`;
  const method = 'POST';

  const formData = new FormData();

  if (data) {
    for (let [key, val] of data.entries()) {
      formData.append(key, val);
    }
  }

  try {
    return await axios({
      method: method,
      url: url,
      timeout: 2000,
      responseType: 'json',
      headers: {
        'Content-Type': 'multipart/form-data',
        // if backend supports u can use gzip request encoding
        // "Content-Encoding": "gzip",
      },
      transformRequest: (data, headers) => {
        // !!! override data to return formData
        // since axios converts that to string
        return formData;
      },
      data: formData,
    }).catch(e => {
      return 9999;
    });
  } catch (error) {
    console.log(error);
  }
}

export async function RequestMap(data) {
  const url = `https://www.juso.go.kr/addrlink/addrLinkApiJsonp.do`;

  const method = 'POST';

  const formData = new FormData();

  if (data) {
    for (let [key, val] of data.entries()) {
      formData.append(key, val);
    }
  }

  try {
    return await axios({
      method: method,
      url: url,
      timeout: 2000,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        // if backend supports u can use gzip request encoding
        // "Content-Encoding": "gzip",
      },
      transformRequest: (data, headers) => {
        // !!! override data to return formData
        // since axios converts that to string
        return formData;
      },
      data: formData,
    }).catch(e => {
      return 9999;
    });
  } catch (error) {
    console.log(error);
  }
}

export async function FileDownLoad(data, httpMethod, httpUrl) {
  try {
    return await axios({
      url: `${server}/api/${httpUrl}`, //your url
      method: httpMethod,
      responseType: 'blob', // important
    });
  } catch (error) {
    console.log(error);
    return 10000;
  }
}

export async function RequestNice(data, httpMethod, httpUrl) {
  try {
    const niceServer = `${server}:5635`;

    const url = `${niceServer}/test.php`;
    const method = httpMethod;

    return await axios({
      method: method,
      url: url,
      timeout: 2000,
      responseType: 'json',
    }).catch(e => {
      console.log(e);
      return 9999;
    });
  } catch (error) {
    console.log(error);
    return 10000;
  }
}
