import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Form,
  CardFooter,
} from 'reactstrap';
import styled from 'styled-components';
import { RequestAxios } from '../utils/request';
import crypto from 'crypto';
import logoImg from '../assets/img/puniclogo.png';
import { useTerm, policy } from '../utils/yackwan';
import { BsFillCaretDownFill } from 'react-icons/bs';
import {
  ToastsStore,
  ToastsContainer,
  ToastsContainerPosition,
} from 'react-toasts';

import insta from '../assets/img/headericon/insta.png';
import instaDeactive from '../assets/img/headericon/insta_deactive.png';
import youtube from '../assets/img/headericon/youtube.png';
import youtubeDeactive from '../assets/img/headericon/youtube_deactive.png';
import blog from '../assets/img/headericon/blog.png';
import blogDeactive from '../assets/img/headericon/blog_deactive.png';
import { Mobile, getInstagram, getYoutube, getBlog } from '../utils/Formcheck';
import Cookies from 'universal-cookie';
import headerImg from '../assets/img/3page_02.jpg';
import FooterPage from '../components/Layout/FooterPage';
import HeaderSocial from '../components/Layout/HeaderSocial';

const cookies = new Cookies();

const StyledInput = styled.input`
  appearance: none;
  border: 1.5px solid gainsboro;
  border-radius: 0.35rem;
  width: 1.5rem;
  height: 1.5rem;

  &:checked {
    border-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    background-size: 100% 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: limegreen;
  }
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
`;

const StyledP = styled.p`
  margin: 0 !important;
  font-size: 17px;
`;

const linkOpen = juso => {
  window.open(`${juso}`);
};

class RegisterAgree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sIv: '',
      sKey: '',
      token_version_id: '',
      enc_data: '',
      integrity_value: '',
      yackwanModal: false,
      yackwanTitle: '',
      yackwanDescription: '',
      agress_useterm: false,
      agress_policy: false,
      joinType: 'user',
      iconInsta: instaDeactive,
      iconYoutube: youtubeDeactive,
      iconBlog: blogDeactive,
    };
    this.decrypt = this.decrypt.bind(this);
    this.getNiceToken = this.getNiceToken.bind(this);
    this.getNiceSecretToken = this.getNiceSecretToken.bind(this);
    this.formSend = this.formSend.bind(this);
    this.encrypt = this.encrypt.bind(this);
  }

  // componentDidMount() {
  //   this.getNiceToken();
  // }

  getNiceToken = async () => {
    try {
      if (this.state.agress_useterm && this.state.agress_policy) {
        let method = 'GET';
        let url = 'user/getNiceToken';
        let data = new Map();

        const response = await RequestAxios(data, method, url);
        const jsonResponse = JSON.parse(response.data);

        console.log(response);

        if (
          jsonResponse.dataHeader.GW_RSLT_CD === '1200' &&
          jsonResponse.dataHeader.GW_RSLT_MSG === '오류 없음'
        ) {
          const getBody = jsonResponse.dataBody;

          if (getBody.access_token) {
            this.getNiceSecretToken(getBody.access_token);
          }
        } else {
          console.log('에러');
        }
      } else {
        throw '서비스 이용 약관 및 개인정보처리방침에 동의를 먼저 해주셔야 본인 인증이 진행됩니다.';
      }
    } catch (error) {
      ToastsStore.error(String(error));
    }
  };

  encrypt = (data, key, iv) => {
    let cipher = crypto.createCipheriv('aes-128-cbc', key, iv);
    let encrypted = cipher.update(data, 'utf8', 'base64');
    encrypted += cipher.final('base64');
    return encrypted;
  };

  decrypt = data => {
    const key = this.state.sKey;
    const iv = this.state.sIv;

    let decipher = crypto.createDecipheriv('aes-128-cbc', key, iv);
    let decrypted = decipher.update(data, 'base64');
    decrypted += decipher.final();

    return decrypted;
  };

  getNiceSecretToken = async niceToken => {
    try {
      let method = 'GET';
      let url = `user/getNiceSecretToken?niceToken=${niceToken}`;
      let data = new Map();

      const response = await RequestAxios(data, method, url);
      const req_dtim = response.data.req_dtim;
      const req_no = response.data.req_no;

      const jsonResponse = JSON.parse(response.data.data);

      if (
        jsonResponse.dataHeader.GW_RSLT_CD === '1200' &&
        jsonResponse.dataHeader.GW_RSLT_MSG === '오류 없음'
      ) {
        const getBody = jsonResponse.dataBody;

        const token_val = getBody.token_val.trim();

        const token_version_id = getBody.token_version_id;
        const result = `${req_dtim.trim()}${req_no.trim()}${getBody.token_val.trim()}`;

        const resultVal = crypto
          .createHash('sha256')
          .update(result)
          .digest('base64');

        const key = resultVal.substr(0, 16);
        const iv = resultVal.substr(
          resultVal.length - 16,
          resultVal.length - 1,
        );
        const hmac_key = resultVal.substr(0, 32);

        this.setState({
          sIv: iv,
          sKey: key,
        });

        const returnUrl = `${window.location.origin}/registeragreeresult`;

        let plain_data = {
          requestno: req_no,
          returnurl: returnUrl,
          methodtype: 'GET',
          sitecode: getBody.site_code,
          authtype: 'M',
        };

        let plain = JSON.stringify(plain_data);
        let enc_data = this.encrypt(plain, key, iv);

        const hmac = crypto.createHmac('sha256', hmac_key);
        const integrity = hmac.update(enc_data).digest('base64');

        this.setState({
          token_version_id: token_version_id,
          enc_data: enc_data,
          integrity_value: integrity,
        });

        this.formSend();
      } else {
        console.log('에러');
      }
    } catch (error) {
      console.log(error);
    }
  };

  formSend = () => {
    const frm = document.createElement('form');
    frm.setAttribute('id', 'form');
    frm.setAttribute(
      'action',
      'https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb',
    );
    frm.setAttribute('method', 'post');
    frm.setAttribute('target', 'auth-form');

    let hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'm');
    hiddenField.setAttribute('value', 'service');
    frm.appendChild(hiddenField);

    hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'token_version_id');
    hiddenField.setAttribute('value', this.state.token_version_id);
    frm.appendChild(hiddenField);

    hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'enc_data');
    hiddenField.setAttribute('value', this.state.enc_data);
    frm.appendChild(hiddenField);

    hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'integrity_value');
    hiddenField.setAttribute('value', this.state.integrity_value);
    frm.appendChild(hiddenField);

    document.body.appendChild(frm);

    window.open(
      'about:blank',
      'auth-form',
      'width=' +
        parseInt(window.innerWidth) * 0.5 +
        ',height=' +
        parseInt(window.innerHeight) * 0.5 +
        ',toolbar=0,menubar=0,location=0,status=0,scrollbars=1,resizable=0,left=0,top=0',
    );
    frm.submit();

    let intervalCookie;

    intervalCookie = setInterval(() => {
      if (cookies.get('auth')) {
        clearInterval(intervalCookie);
        this.props.history.push({
          pathname: '/registerauth',
          state: {
            userInfo: JSON.parse(this.decrypt(cookies.get('auth'))),
            joinType: this.state.joinType,
          },
        });
        cookies.remove('auth');
        // this.props.history.push('/registerauth');
      }
    }, 1000);
  };

  render() {
    return (
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ToastsContainer
          position={ToastsContainerPosition.BOTTOM_RIGHT}
          store={ToastsStore}
          lightBackground
        />

        <Modal
          key={'yackwanModal'}
          isOpen={this.state.yackwanModal}
          centered={true}
          style={{
            minWidth: '90%',
          }}
        >
          <ModalHeader>
            {this.state.yackwanTitle ? this.state.yackwanTitle : ''}
          </ModalHeader>
          <ModalBody className="pionicModalTable">
            <div
              style={{
                height: 'auto',
                minHeight: 300,
                maxHeight: 300,
                overflowY: 'auto',
                padding: 30,
                whiteSpace: 'pre-wrap',
                fontSize: 14,
              }}
            >
              {this.state.yackwanDescription
                ? this.state.yackwanDescription
                : ''}
            </div>
          </ModalBody>
          <ModalFooter
            centered="true"
            className="justify-content-center"
            style={{ borderTopWidth: 0 }}
          >
            <Button
              color="primary"
              onClick={() => {
                if (this.state.yackwanTitle === '서비스 이용 약관') {
                  this.setState({
                    yackwanModal: false,
                    agress_useterm: true,
                  });
                } else {
                  this.setState({
                    yackwanModal: false,
                    agress_policy: true,
                  });
                }
              }}
            >
              동의
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                this.setState({ yackwanModal: false });
              }}
            >
              닫기
            </Button>
          </ModalFooter>
        </Modal>
        <Col md={12} lg={12}>
          <Card body style={{ borderWidth: 0 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingBottom: 10,
                  marginBottom: 10,
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid',
                  borderBottomColor: '#dee2e6',
                }}
              >
                <HeaderSocial logo={'/'} />
              </div>

              <div
                style={
                  Mobile()
                    ? { overflowY: 'hidden', height: 150 }
                    : { overflowY: 'hidden', height: 300 }
                }
              >
                <img src={`${headerImg}`} style={{ width: '100%' }} />
              </div>

              <div
                style={{
                  marginTop: 30,
                  marginBottom: 10,
                  padding: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  whiteSpace: 'pre-wrap',
                  fontSize: 16,
                }}
              >
                안녕하세요 고객님
                <br />
                회원가입을 진행하실려면 약관 동의 및 본인인증을 먼저 해주셔야
                합니다
                <br />
              </div>

              <div
                style={{
                  width: '100%',
                  marginBottom: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '15%',
                  fontSize: 16,
                }}
              >
                <Button
                  outline={this.state.joinType == 'user' ? false : true}
                  color={'success'}
                  onClick={() => {
                    this.setState({
                      joinType: 'user',
                    });
                  }}
                >
                  사용자
                </Button>
                <Button
                  outline={this.state.joinType == 'user' ? true : false}
                  color={'success'}
                  onClick={() => {
                    this.setState({
                      joinType: 'admin',
                    });
                  }}
                >
                  관리자 전용
                </Button>
              </div>

              <CardHeader
                style={{
                  width: '100%',
                  maxWidth: 500,
                  fontSize: 20,
                  textAlign: 'center',
                }}
              >
                약관 동의 및 본인인증 확인
              </CardHeader>
              <CardBody style={{ width: '100%', maxWidth: 500 }}>
                <Form>
                  <StyledLabel htmlFor={'useTerm'}>
                    <StyledP
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({
                          yackwanModal: true,
                          yackwanTitle: '서비스 이용 약관',
                          yackwanDescription: useTerm(),
                        });
                      }}
                    >
                      서비스 이용 약관 <BsFillCaretDownFill />
                    </StyledP>
                    <StyledInput
                      type="checkbox"
                      onChange={() => {
                        if (!this.state.agress_useterm) {
                          this.setState({
                            agress_useterm: true,
                          });
                        } else {
                          this.setState({
                            agress_useterm: false,
                          });
                        }
                      }}
                      checked={this.state.agress_useterm}
                    />
                  </StyledLabel>

                  <StyledLabel htmlFor={'privacy'}>
                    <StyledP
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({
                          yackwanModal: true,
                          yackwanTitle: '개인정보처리방침',
                          yackwanDescription: policy(),
                        });
                      }}
                    >
                      개인정보처리방침 <BsFillCaretDownFill />
                    </StyledP>
                    <StyledInput
                      type="checkbox"
                      onChange={() => {
                        if (!this.state.agress_policy) {
                          this.setState({
                            agress_policy: true,
                          });
                        } else {
                          this.setState({
                            agress_policy: false,
                          });
                        }
                      }}
                      checked={this.state.agress_policy}
                    />
                  </StyledLabel>
                </Form>
              </CardBody>

              <CardFooter
                style={{
                  width: '100%',
                  maxWidth: 500,
                  fontSize: 20,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  color={'primary'}
                  outline
                  onClick={() => {
                    this.props.history.push('/');
                  }}
                >
                  돌아가기
                </Button>

                <Button
                  outline
                  onClick={() => {
                    this.getNiceToken();
                  }}
                >
                  본인인증 하러 가기
                </Button>
              </CardFooter>
              <CardFooter
                style={{
                  width: '100%',
                  maxWidth: 500,
                  fontSize: 11,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <FooterPage />
              </CardFooter>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default RegisterAgree;
