import React from 'react';
import {
  Button,
  Card,
  CardFooter,
  Col,
  Modal,
  ModalBody,
  Row,
  Input,
} from 'reactstrap';

import {
  ToastsStore,
  ToastsContainer,
  ToastsContainerPosition,
} from 'react-toasts';
import {
  getDomain,
  getImageDomain,
  Mobile,
  getInstagram,
  getYoutube,
  getBlog,
} from '../utils/Formcheck';

import { css } from '@emotion/react';
import Loader from 'react-spinners/RotateLoader';

import { BsGeoAlt } from 'react-icons/bs';

import styled from 'styled-components';
import { RequestAxios } from '../utils/request';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { isMobile } from 'react-device-detect';
import 'moment/locale/ko';
import moment from 'moment';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-datepicker/dist/react-datepicker.css';

import siMap from '../assets/geo-data/sido.json';
import guMap from '../assets/geo-data/gugun.json';

import insta from '../assets/img/headericon/insta.png';
import instaDeactive from '../assets/img/headericon/insta_deactive.png';
import youtube from '../assets/img/headericon/youtube.png';
import youtubeDeactive from '../assets/img/headericon/youtube_deactive.png';
import blog from '../assets/img/headericon/blog.png';
import blogDeactive from '../assets/img/headericon/blog_deactive.png';
import logoImg from '../assets/img/puniclogo.png';
import headerImg from '../assets/img/2pagae_02.jpg';
import FooterPage from '../components/Layout/FooterPage';
import HeaderSocial from '../components/Layout/HeaderSocial';

const domain = getDomain();
const imageLink = `${getImageDomain()}/api/image`;

const TableFooter = styled.div`
  display: flex;
  align-itmes: center;
  justify-content: center;
  margin-top: 10px;
`;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const linkOpen = juso => {
  window.open(`${juso}`);
};

const mapOpen = juso => {
  let jusoR = juso.replace(/\s/gi, '+');
  window.open(`https://map.kakao.com/?map_type=TYPE_MAP&q=${jusoR}&urlLevel=2`);
};

class DeviceFind extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      confirmType: '',
      confirmPopup: false,
      addressPopup: false,
      editModal: false,
      pwdModal: false,
      betteryModal: false,
      editType: true,
      currentPage: 1,
      csvType: 'all',
      betteryPoint: {},
      columns: [
        {
          dataField: 'deviceId',
          text: '설치사진',
          sort: true,
          formatter: this.gallalyFormat,
        },
      ],
      selectedProducts: [],
      products: [],
      adminList: [],
      initProducts: [],
      guMap: [],
      dongMap: [],
      sido1: '전체',
      sido1Code: '전체',
      sido2: '전체',
      sido2Code: '전체',
      sido3: '전체',
      sido3Code: '전체',
      adminId: '',
      keyword: '',
      fday: moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'),
      lday: moment(new Date()).format('YYYY-MM-DD'),
      width: isMobile ? window.innerWidth - 30 : '100%',
      sPhoto: {},
      sPhotoView: '',
      sName: '',
      sIP: '',
      sContact: '',
      sInstallDate: moment(new Date()).format('YYYY-MM-DD'),
      sAdmin: '',
      sSido1: '선택안함',
      sSido2: '선택안함',
      sSido3: '선택안함',
      sAddress: '',
      sIDX: '',
      sPWD: '',
      cPWDRe: '',
      cPWD: '',
      sCPWD: '',
      cCPWDRe: '',
      cCPWD: '',
      sDailyLimit: 50,
      sDeviceId: '',
      sPublic: 'N',
      iconInsta: instaDeactive,
      iconYoutube: youtubeDeactive,
      iconBlog: blogDeactive,
    };

    this.getDevices = this.getDevices.bind(this);
    this.openDatePicker = this.openDatePicker.bind(this);
    this.search = this.search.bind(this);
    this.searchInit = this.searchInit.bind(this);
    this.betteryFormat = this.betteryFormat.bind(this);
    this.gallalyFormat = this.gallalyFormat.bind(this);
  }

  gallalyFormat = deviceId => {
    if (deviceId) {
      const findItem = this.state.initProducts.find(
        v => v.deviceId === deviceId,
      );

      return (
        <div
          style={
            Mobile() ? { width: '100%' } : { fontWeight: 'bold', fontSize: 15 }
          }
        >
          <div
            style={{
              height: 200,
              overflow: 'hidden',
            }}
          >
            <img
              style={{ height: '100%', width: `auto` }}
              onError={e => {
                e.target.src = logoImg;
              }}
              src={
                findItem.devicePhoto
                  ? `${imageLink}/${findItem.devicePhoto}`
                  : ''
              }
            />
          </div>
          <br />
          <div>{findItem.location ? findItem.location : ''}</div>
          <div>
            {findItem.address ? findItem.address : ''}{' '}
            {findItem.address ? (
              <BsGeoAlt
                onClick={() => {
                  mapOpen(findItem.address);
                }}
              />
            ) : (
              ''
            )}
          </div>
          <div>
            담당자: {findItem.contact ? findItem.contact : '070-8277-4577'}
          </div>
          <br />
        </div>
      );
    }

    return <span>{deviceId}2</span>;
  };

  betteryFormat = cell => {
    if (cell) {
      return (
        <Button
          color="primary"
          outline
          onClick={event => {
            event.stopPropagation();

            this.setState({
              sIDX: cell,
              betteryModal: true,
              betteryPoint:
                this.state.initProducts &&
                this.state.initProducts.find(v => v.IDX === cell).point,
            });
          }}
        >
          보기
        </Button>
      );
    }

    return (
      <Button
        color="primary"
        outline
        onClick={event => {
          event.stopPropagation();
          this.setState({
            betteryModal: true,
          });
        }}
      >
        보기
      </Button>
    );
  };

  getDevices = async () => {
    try {
      this.setState({
        loading: true,
      });

      let method = 'GET';
      let url = 'device/list';
      let data = '';
      const response = await RequestAxios(data, method, url);

      if (response !== 9999 || response !== 10000) {
        if (response.data.result === 0) {
          this.setState({
            products: [],
            initProducts: [],
          });

          const filterList = response.data.info.filter(item => {
            return item.public === 'Y';
          });

          this.setState({
            products: filterList,
            initProducts: filterList,
          });
        } else {
        }
      } else {
        if (response === 10000) {
          throw '사용할수 없는 문자열이 포함되어 있습니다.';
        }
        this.getDevices();
      }

      this.setState({
        loading: false,
      });
    } catch (error) {
      ToastsStore.error(String(error));

      this.setState({
        loading: false,
      });
    }
  };

  openDatePicker = dateFormType => {
    dateFormType.setOpen(true);
  };

  componentDidMount() {
    this.getDevices();

    console.log(logoImg);

    siMap.features.sort((a, b) => {
      if (a.properties.ctp_kor_nm < b.properties.ctp_kor_nm) {
        return -1;
      }

      if (a.properties.ctp_kor_nm > b.properties.ctp_kor_nm) {
        return 1;
      }
      return 0;
    });

    guMap.features.sort((a, b) => {
      if (a.properties.sig_kor_nm < b.properties.sig_kor_nm) {
        return -1;
      }

      if (a.properties.sig_kor_nm > b.properties.sig_kor_nm) {
        return 1;
      }
      return 0;
    });
  }

  searchInit = () => {
    this.setState({
      currentPage: 1,
      sido1: '전체',
      sido1Code: '전체',
      keyword: '',
      products: this.state.initProducts,
    });
  };

  search = () => {
    let searchProduct = this.state.initProducts;

    if (this.state.sido1 !== '전체') {
      searchProduct = searchProduct.filter(
        item => item.sido === this.state.sido1,
      );
    }

    if (this.state.keyword) {
      searchProduct = searchProduct.filter(item => {
        return item.location.indexOf(this.state.keyword) !== -1;
      });
    }

    this.setState({
      currentPage: 1,
      products: searchProduct,
    });
  };

  render() {
    return (
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ToastsContainer
          position={ToastsContainerPosition.BOTTOM_RIGHT}
          store={ToastsStore}
          lightBackground
        />

        <Modal
          key={'loadingModal'}
          isOpen={this.state.loading}
          centered={true}
          className="loading-container"
          fade={false}
        >
          <ModalBody style={{ display: 'none' }}></ModalBody>
          <div className="overlay-box" width="100%">
            <Loader
              color={'#fff'}
              loading={this.state.loading}
              css={override}
              size={15}
              // height={400}
              // width={100}
              // radius={10}
              // margin={20}
              speedMultiplier={0.4}
            />
          </div>
        </Modal>

        <Col lg={12} md={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              borderBottomColor: '#dee2e6',
            }}
          >
            <div
              style={{
                width: '90%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <HeaderSocial logo={'/'} />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={
                Mobile()
                  ? {
                      width: '100%',
                      height: 300,
                      backgroundImage: `url(${headerImg})`,
                      backgroundSize: 'cover',
                      backgroundPosition: '50%,50%',
                    }
                  : {
                      width: '30%',
                      height: 300,
                      backgroundImage: `url(${headerImg})`,
                      backgroundSize: 'cover',
                      backgroundPosition: '50%,50%',
                    }
              }
            ></div>

            <div
              style={
                Mobile()
                  ? {
                      width: '100%',
                      marginTop: 10,
                      paddingTop: 10,
                      fontSize: 18,
                    }
                  : {
                      width: '60%',
                      marginTop: 15,
                      padding: 0,
                      borderRadius: 10,
                      fontSize: 20,
                    }
              }
            >
              <div style={{ padding: 0, textAlign: 'center' }}>
                <font style={{ fontSize: 23, fontWeight: 'bolder' }}>
                  CUSTOMER CENTER
                  <br />
                  퓨닉 위치
                </font>
              </div>

              <div style={{ paddingLeft: '5%', textAlign: 'left' }}>
                <Button
                  outline
                  color={'primary'}
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  돌아가기
                </Button>
              </div>
            </div>
          </div>

          <div
            style={
              Mobile()
                ? {
                    width: '100%',
                    flex: 1,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 10,
                    marginBottom: 20,
                  }
                : {
                    width: '100%',
                    flex: 1,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 10,
                    marginBottom: 20,
                  }
            }
          >
            <div
              style={
                Mobile()
                  ? {
                      width: '30%',
                      height: '100%',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      backgroundColor: '#eee',
                      marginTop: 50,
                      marginBottom: 20,
                    }
                  : {
                      width: '20%',
                      height: '100%',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      backgroundColor: '#eee',
                      marginTop: 50,
                      marginBottom: 20,
                    }
              }
            >
              <span
                style={{
                  flex: 1,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 9,
                  fontWeight: 'bold',
                }}
              >
                지역
              </span>

              <span
                style={{
                  flex: 1,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: 5,
                }}
              >
                <Input
                  type="select"
                  name="sido1"
                  style={{ flex: 1, marginLeft: 5 }}
                  onChange={e => {
                    this.setState({
                      sido1: e.target.options[e.target.selectedIndex].text,
                      sido1Code: e.target.value,
                    });
                  }}
                  value={this.state.sido1Code}
                >
                  <option
                    onClick={() => {
                      this.setState({
                        sido1: '전체',
                        sido1Code: '전체',
                      });
                    }}
                    value={'전체'}
                  >
                    전체
                  </option>
                  {siMap &&
                    siMap.features.map(item => {
                      return (
                        <option
                          key={item.properties.ctprvn_cd}
                          value={item.properties.ctprvn_cd}
                        >
                          {item.properties.ctp_kor_nm}
                        </option>
                      );
                    })}
                </Input>
              </span>
            </div>

            <div
              style={
                Mobile()
                  ? {
                      width: '60%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      marginTop: 50,
                      marginBottom: 20,
                    }
                  : {
                      width: '35%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      marginTop: 50,
                      marginBottom: 20,
                    }
              }
            >
              <span
                style={{
                  flex: 0.7,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 5,
                }}
              >
                <Input
                  type="text"
                  style={{ height: 87 }}
                  placeholder={'지점'}
                  onChange={text => {
                    this.setState({
                      keyword: text.target.value,
                    });
                  }}
                />
              </span>
              <span
                style={{
                  flex: 0.3,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 5,
                }}
              >
                <Button
                  outline
                  style={{ height: 87, width: '100%' }}
                  onClick={() => {
                    this.search();
                  }}
                >
                  검색
                </Button>
              </span>
            </div>
          </div>

          <ToolkitProvider
            keyField="IDX"
            data={this.state.products}
            columns={this.state.columns}
            exportCSV={{
              // exportAll:this.state.csvType === 'all'? true : false,
              exportAll: true,
              onlyExportSelection: this.state.csvType !== 'all' ? true : false,
              blobType: 'text/csv;charst=utf-8',
              fileName: 'device.csv',
            }}
          >
            {props => (
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  totalSize: this.state.products.length,
                  sizePerPageList: [10, 50, 100],
                  onPageChange: page => {
                    this.setState({
                      currentPage: page,
                    });
                  },
                  page: this.state.currentPage,
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <div
                    style={
                      Mobile()
                        ? { width: '100%' }
                        : {
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }
                    }
                    className={'deviceTable'}
                  >
                    <div style={Mobile() ? {} : { width: '60%' }}>
                      <BootstrapTable
                        className={'device_table'}
                        ref={n => (this.node = n)}
                        hover
                        {...props.baseProps}
                        options={{
                          exportCSVSeparator: '##',
                          page: this.state.currentPage,
                        }}
                        {...paginationTableProps}
                        headerClasses={'table_header_col_hidden'}
                        classes={'gallalyDesign'}
                      />

                      <TableFooter>
                        <PaginationListStandalone {...paginationProps} />
                      </TableFooter>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            )}
          </ToolkitProvider>

          <Card style={{ border: 0 }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 10,
                  paddingBottom: 10,
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid',
                  borderBottomColor: '#dee2e6',
                }}
              >
                <CardFooter
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: '7%',
                    fontSize: 11,
                  }}
                >
                  <FooterPage />
                </CardFooter>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default DeviceFind;
